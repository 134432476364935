import { updateActionPlan } from '@api/actionPlan';
import Card from '@components/Atoms/Card';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import Modal from '@components/Atoms/Modal';
import StaticDatePickerX from '@components/Atoms/StaticDatePicker';
import Checkbox from '@components/Checkbox';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { Grid } from '@mui/material';
import { ImprovePlanAction } from 'custom';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import CloseIcon from '@mui/icons-material/Close';
import CalendarIcon from '../../../../assets/images/calendar.svg';
import startIcon from '../../../../assets/images/icons/plus-circle.svg';
import x from '../../../../assets/images/icons/x.svg';
import iosBack from '../../../../assets/images/ios-back.svg';
import iosNext from '../../../../assets/images/ios-next.svg';
import styles from './index.module.scss';

const themeColors = {
  sunYellow: '#FCD300',
  aquaGreen: '#06BF97',
  calmLilac: '#BEAAF3',
  warmOrange: '#FF8A4A',
  zingyGreen: '#8EEA36',
  skyBlue: '#019DF2',
  default: '#ECE7DC',
};

const ImproveActionCard: React.FunctionComponent<ImproveActionCardProps> = (props) => {
  const {
    actionData,
    className,
    title,
    description,
    dueDate,
    overdue,
    completed,
    isRecommendedSection,
    actionImage,
    theme,
    actionButtonName,
    handleRefetch,
    handleActions,
    filterActiveActions,
    handleProgress,
    hideOptions,
  } = props;
  const { isDesktop } = useWindowDimensions();
  const history = useHistory();
  const [startIndex, setStartIndex] = useState(0);
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [selectedDueDate, setSelectedDueDate] = useState<any>(
    dayjs(new Date()).format('YYYY-MM-DD'),
  );
  const [markAsCompleted, setMarkAsCompleted] = useState<boolean>(false);
  const [dueDateUpdated, setDueDateUpdated] = useState<boolean>(false);
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (dueDateUpdated) {
      handleUpdateAction(
        {
          id: actionData.improvePlanActionId,
          actionType: actionData.actionType,
          dueDate: selectedDueDate,
          done: null,
          removed: null,
          start: null,
        },
        'Due date updated successfully',
      );
      setDatePickerOpen(false);
      setDueDateUpdated(false);
    }
  }, [dueDateUpdated, selectedDueDate]);

  const handleNext = () => {
    setStartIndex(startIndex + 1);
  };

  const handleBack = () => {
    setStartIndex(startIndex - 1);
  };

  const items = [
    {
      image: 'https://res.cloudinary.com/amantha/image/upload/v1707804155/gw1f3jmzxkvf6wgvzdmj.svg',
      title: 'Stephen Norris',
      description:
        'I help busy professionals navigate stress, build resilience, and create work-life balance.',
      role: 'psychologist',
    },
    {
      image: 'https://res.cloudinary.com/amantha/image/upload/v1707804152/q1hz3qpmv7ukoqflwgkb.svg',
      title: 'Susan Birnel',
      description:
        'I guide individuals and teams to discover their authentic selves, cultivate healthy habits, and achieve lasting well-being.',
      role: 'therapist',
    },
    {
      image: 'https://res.cloudinary.com/amantha/image/upload/v1707804148/sdsdljledpjxkg2sock4.svg',
      title: 'Angela Harding',
      description:
        'I help individuals and teams unlock their full potential through personalised wellbeing coaching.',
      role: 'life coach',
    },
    {
      image: 'https://res.cloudinary.com/amantha/image/upload/v1707804155/gw1f3jmzxkvf6wgvzdmj.svg',
      title: 'Stephen Norris',
      description:
        'I help busy professionals navigate stress, build resilience, and create work-life balance.',
      role: 'psychologist',
    },
    {
      image: 'https://res.cloudinary.com/amantha/image/upload/v1707804152/q1hz3qpmv7ukoqflwgkb.svg',
      title: 'Susan Birnel',
      description:
        'I guide individuals and teams to discover their authentic selves, cultivate healthy habits, and achieve lasting well-being.',
      role: 'therapist',
    },
    {
      image: 'https://res.cloudinary.com/amantha/image/upload/v1707804148/sdsdljledpjxkg2sock4.svg',
      title: 'Angela Harding',
      description:
        'I help individuals and teams unlock their full potential through personalised wellbeing coaching.',
      role: 'life coach',
    },
  ];

  const imageUrl = actionImage
    ? `../../../images/improve-actions/${actionImage.toLowerCase()}-${
        isDesktop ? 'desktop' : 'mobile'
      }.png`
    : `../../../images/improve-actions/default-${isDesktop ? 'desktop' : 'mobile'}.png`;

  const handleDatePickerChange = (date: string | null) => {
    if (date) {
      setSelectedDueDate(date);
    }
  };

  const handleMarkCompleted = async (e: { target: { checked: boolean } }) => {
    await handleUpdateAction(
      {
        id: actionData.improvePlanActionId,
        actionType: actionData.actionType,
        dueDate: null,
        done: e.target.checked,
        removed: null,
        start: null,
      },
      'Action updated',
    );
    setMarkAsCompleted(e.target.checked);
    handleRefetch();
  };

  const handleUpdateAction = async (
    data: {
      id: number;
      actionType: string;
      dueDate: string | null;
      done: boolean | null;
      removed: boolean | null;
      start: boolean | null;
    },
    message?: string,
  ) => {
    try {
      const res = await updateActionPlan(data);
      const planIdValue = res.id;
      const progressValue = res.progress;
      if (filterActiveActions) {
        handleActions(
          res?.improvePlanActions
            ?.filter(
              (dt: ImprovePlanAction) =>
                dt.improvePlanActionDone === filterActiveActions && !dt.improvePlanActionRemoved,
            )
            .sort(
              (
                a: { improvePlanActionDueDays: number | null },
                b: { improvePlanActionDueDays: number | null },
              ) => {
                if (a.improvePlanActionDueDays === b.improvePlanActionDueDays) {
                  return 0;
                }

                // nulls sort after anything else
                if (a.improvePlanActionDueDays === null) {
                  return 1;
                }

                if (b.improvePlanActionDueDays === null) {
                  return -1;
                }

                return a.improvePlanActionDueDays > b.improvePlanActionDueDays ? 1 : -1;
              },
            ),
        );

        handleProgress({ planId: planIdValue, progress: progressValue });
      } else {
        handleActions(
          res?.improvePlanActions
            ?.filter(
              (dt: ImprovePlanAction) =>
                dt.improvePlanActionDone === false && !dt.improvePlanActionRemoved,
            )
            .sort(
              (
                a: { improvePlanActionDueDays: number | null },
                b: { improvePlanActionDueDays: number | null },
              ) => {
                if (a.improvePlanActionDueDays === b.improvePlanActionDueDays) {
                  return 0;
                }

                // nulls sort after anything else
                if (a.improvePlanActionDueDays === null) {
                  return 1;
                }

                if (b.improvePlanActionDueDays === null) {
                  return -1;
                }

                return a.improvePlanActionDueDays > b.improvePlanActionDueDays ? 1 : -1;
              },
            ),
        );

        handleProgress({ planId: planIdValue, progress: progressValue });
      }

      toast.success(message);
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message || `Error. Try again later`);
      } else {
        toast.error(`Error. Try again later`);
      }
    }
  };

  return (
    <>
      <Card className={`${className} ${hideOptions && styles.noOptions}`}>
        <div className={styles.container}>
          <div
            className={styles.cardActionImageCont}
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}
          />

          <div className={styles.cardContentDesktop}>
            <div className={styles.cardHeaderSection}>
              <h3 className={styles.cardTitle}>{title}</h3>
              {!actionData?.improvePlanActionDone && !hideOptions && (
                <span onClick={() => setRemoveModalOpen(true)}>
                  remove
                  <i className="icon icon-x" />
                </span>
              )}
            </div>

            <div className={styles.cardMiddleContainer}>
              <p className={styles.description}>{description}</p>
              <div className={styles.btnContainer}>
                {actionData.actionButton !== null && (
                  <DefaultButton
                    type="button"
                    className={`${styles.hsPrimaryBtn} ${hideOptions && styles.noOptions}`}
                    variant="primary"
                    onClick={() => {
                      if (
                        actionData.improvePlanActionStartAt === null &&
                        !hideOptions &&
                        actionData.actionType !== 'USER_ACTION'
                      ) {
                        handleUpdateAction(
                          {
                            id: actionData.improvePlanActionId,
                            actionType: actionData.actionType,
                            dueDate: null,
                            done: null,
                            removed: null,
                            start: true,
                          },
                          'Action started',
                        );
                      }

                      if (actionData.actionURL) {
                        window.location.href = actionData.actionURL;
                      }
                    }}
                  >
                    <span>{actionButtonName || 'Start now'}</span>
                    <Img src={startIcon} alt="HS Start Icon" className={styles.hsStartIcon} />
                  </DefaultButton>
                )}

                <div className={`${styles.doneBtn} ${hideOptions && styles.noOptions}`}>
                  <Checkbox
                    id={actionData.actionTitle}
                    text={actionData.improvePlanActionDone ? 'Completed' : 'Mark as complete'}
                    color={themeColors[theme as keyof typeof themeColors] || themeColors.default}
                    onChange={handleMarkCompleted}
                    checked={
                      actionData.improvePlanActionDone
                        ? actionData.improvePlanActionDone
                        : markAsCompleted
                    }
                    disabled={
                      actionData.actionType !== 'USER_ACTION' &&
                      actionData.improvePlanActionStartAt === null &&
                      hideOptions
                    }
                  />
                </div>
              </div>
            </div>

            {!hideOptions ? (
              <div
                className={`${styles.tooltipCont} ${
                  actionData?.improvePlanActionDone && styles.disabled
                }`}
                onClick={() => {
                  if (!actionData?.improvePlanActionDone) setDatePickerOpen(true);
                }}
              >
                <div
                  className={styles.footerTooltipCont}
                  id="footerTooltipCont"
                  data-tip
                  data-for="footerTooltipCont"
                >
                  <div className={styles.cardFooterSection}>
                    {overdue !== null && actionData.improvePlanActionDueDate ? (
                      <div className={overdue ? styles.dueSectionError : styles.dueSectionSuccess}>
                        {dueDate}
                      </div>
                    ) : (
                      <div className={styles.setDueDate}>Set due date</div>
                    )}

                    <div className={styles.calendarBtn}>
                      <Img src={CalendarIcon} alt="Calendar Icon" className={styles.calendar} />
                    </div>
                  </div>
                </div>{' '}
                <ReactTooltip id="footerTooltipCont">
                  <span>Set due date</span>
                </ReactTooltip>
              </div>
            ) : (
              <div>&nbsp;</div>
            )}

            {isRecommendedSection && (
              <div className={styles.reccomendationSection}>
                <div className={styles.topContainer}>
                  <h3 className={styles.reccomendedSectionTitle}>
                    Recommended professionals for you
                  </h3>
                  <div className={styles.navigationButtons}>
                    <DefaultButton
                      onClick={handleBack}
                      disabled={startIndex === 0}
                      className={styles.navigationBtn}
                    >
                      <Img src={iosBack} alt="Back" className={styles.navigationIcon} />
                    </DefaultButton>
                    <DefaultButton
                      onClick={handleNext}
                      disabled={startIndex + 3 >= items.length}
                      className={styles.navigationBtn}
                    >
                      <Img src={iosNext} alt="Next" className={styles.navigationIcon} />
                    </DefaultButton>
                  </div>
                </div>

                <div className={styles.carousel}>
                  <Grid container spacing={3} className={styles.carouselItemsContainer}>
                    {items.slice(startIndex, startIndex + 3).map((item, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <div key={index} className={styles.carouselItem}>
                          <div className={styles.carouselItemContainer}>
                            <Img src={item.image} alt={item.title} className={styles.carouselImg} />
                            <div className={styles.carouselTextContainer}>
                              <h3 className={styles.carouseTitle}>{item.title}</h3>
                              <p className={styles.carouseDescription}>{item.description}</p>
                              <div className={styles.roleContainer}>{item.role}</div>
                            </div>
                          </div>

                          <div className={styles.arrowButton}>
                            <div>
                              <svg
                                style={{ cursor: 'pointer' }}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 87 72"
                              >
                                <polygon
                                  className={styles.blogArrowBg}
                                  id="Path"
                                  points="86.96 1.33 1.15 21.53 31.69 71.92 86.96 71.97 86.96 1.33"
                                />
                                <g id="Arrow">
                                  <g id="Group-2">
                                    <polygon points="50.66 49.89 48.96 48.19 61.18 35.98 48.96 23.76 50.66 22.06 64.57 35.98 50.66 49.89" />
                                    <rect x="36.75" y="34.78" width="26.13" height="2.4" />
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={styles.cardContentMobile}>
          <div
            className={styles.cardActionImageMobile}
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}
          />

          <div className={`${styles.mobileCardContentContainer}`}>
            <div className={styles.mobileMiddleContainer}>
              <h3 className={styles.mobileCardTitle}>{title}</h3>
              <p className={styles.mobileCardDescription}>{description}</p>

              <div className={styles.mobileButtonContainer}>
                {actionData.actionButton !== null && (
                  <DefaultButton
                    type="button"
                    className={`${styles.hsPrimaryBtn} ${styles.fullWidth}`}
                    variant="primary"
                    path={actionData.actionURL !== null ? actionData.actionURL : null}
                    onClick={() => {
                      if (
                        actionData.improvePlanActionStartAt === null &&
                        actionData.actionType !== 'USER_ACTION'
                      ) {
                        handleUpdateAction(
                          {
                            id: actionData.improvePlanActionId,
                            actionType: actionData.actionType,
                            dueDate: null,
                            done: null,
                            removed: null,
                            start: true,
                          },
                          'Action started',
                        );
                      }

                      if (actionData.actionURL) {
                        window.location.href = actionData.actionURL;
                      }
                    }}
                  >
                    <span>{actionButtonName || 'Start now'}</span>
                    <Img src={startIcon} alt="HS Start Icon" className={styles.hsStartIcon} />
                  </DefaultButton>
                )}

                <div className={styles.doneBtn}>
                  <Checkbox
                    id={actionData.actionTitle}
                    text={actionData.improvePlanActionDone ? 'Completed' : 'Mark as complete'}
                    color={themeColors[theme as keyof typeof themeColors] || themeColors.default}
                    onChange={handleMarkCompleted}
                    checked={
                      actionData.improvePlanActionDone
                        ? actionData.improvePlanActionDone
                        : markAsCompleted
                    }
                    disabled={
                      actionData.actionType !== 'USER_ACTION' &&
                      actionData.improvePlanActionStartAt === null
                    }
                  />
                </div>

                <div className={styles.mobileCardFooter}>
                  <div
                    className={styles.mobileDueSection}
                    onClick={() => {
                      if (!actionData?.improvePlanActionDone) setDatePickerOpen(true);
                    }}
                  >
                    {overdue !== null && actionData.improvePlanActionDueDate ? (
                      <div className={overdue ? styles.dueSectionError : styles.dueSectionSuccess}>
                        {dueDate}
                      </div>
                    ) : (
                      <div className={styles.setDueDate}>Set due date</div>
                    )}

                    <div className={styles.calendarBtn}>
                      <Img src={CalendarIcon} alt="Calendar Icon" className={styles.calendar} />
                    </div>
                  </div>

                  {!actionData?.improvePlanActionDone && (
                    <div className={styles.removeMobile}>
                      <span onClick={() => setRemoveModalOpen(true)}>remove</span>
                      <CloseIcon className={styles.removeIcon} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>

      {removeModalOpen && (
        <Modal
          open={removeModalOpen}
          setOpen={setRemoveModalOpen}
          overlay
          onTapBackgroundClose
          className={styles.removeModal}
        >
          <div className={styles.removeModalContent}>
            <div className={styles.icons} onClick={() => setRemoveModalOpen(false)}>
              <div className={styles.close}>Close</div>
              <img src={x} alt="close icon" />
            </div>
            <h4>Are you sure you want to remove this action?</h4>
            <p>Action will be removed from your plan permanently. Please confirm.</p>

            <div className={styles.btnContainer}>
              <DefaultButton
                className={styles.hsSecondaryBtn}
                onClick={() => setRemoveModalOpen(false)}
              >
                No
              </DefaultButton>
              <DefaultButton
                className={`${styles.hsPrimaryBtn} ${styles.discardFullWidth}`}
                onClick={() => {
                  handleUpdateAction(
                    {
                      id: actionData.improvePlanActionId,
                      actionType: actionData.actionType,
                      dueDate: null,
                      done: null,
                      removed: true,
                      start: null,
                    },
                    'Action removed',
                  );
                  setRemoveModalOpen(false);
                }}
              >
                Yes
              </DefaultButton>
            </div>
          </div>
        </Modal>
      )}

      {datePickerOpen && (
        <Modal
          open={datePickerOpen}
          setOpen={setDatePickerOpen}
          overlay
          onTapBackgroundClose
          className={styles.datePickerModal}
        >
          <StaticDatePickerX
            selectedDate={selectedDueDate}
            handleChange={handleDatePickerChange}
            onClose={() => setDatePickerOpen(false)}
            onAccept={() => {
              setDueDateUpdated(true);
            }}
          />
        </Modal>
      )}
    </>
  );
};

ImproveActionCard.defaultProps = {
  className: styles.card,
  overdue: false,
  completed: false,
  isRecommendedSection: false,
  actionImage: null,
  theme: '',
  hideOptions: false,
};

type ImproveActionCardProps = {
  actionData: any;
  className?: string;
  title: string;
  description: string;
  dueDate: string;
  overdue?: boolean | null;
  completed?: boolean;
  isRecommendedSection?: boolean;
  actionImage?: string | null;
  theme?: string | null;
  actionButtonName: string | null;
  handleRefetch: () => void;
  handleActions: (arg1: any) => void;
  filterActiveActions: boolean;
  handleProgress: any;
  hideOptions?: boolean;
};

export default ImproveActionCard;
