import React from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './index.module.scss';

const Tooltip: React.FunctionComponent<TooltipProps> = (props) => {
  const { tip } = props;

  return (
    <span>
      <svg
        data-tip={tip}
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" width="16" height="16" rx="8" fill="#505050" />
        <path
          d="M11.349 6.106C11.349 6.68667 11.2753 7.12867 11.128 7.432C10.9893 7.73533 10.699 8.06033 10.257 8.407C9.815 8.75367 9.52033 9.031 9.373 9.239C9.23433 9.43833 9.165 9.655 9.165 9.889V10.318H8.021C7.813 10.0493 7.709 9.71133 7.709 9.304C7.709 9.08733 7.79133 8.87067 7.956 8.654C8.12067 8.43733 8.41533 8.15567 8.84 7.809C9.26467 7.45367 9.54633 7.18067 9.685 6.99C9.82367 6.79067 9.893 6.53067 9.893 6.21C9.893 5.57733 9.43367 5.261 8.515 5.261C7.995 5.261 7.449 5.32167 6.877 5.443L6.578 5.508L6.5 4.429C7.306 4.143 8.03833 4 8.697 4C9.607 4 10.2743 4.169 10.699 4.507C11.1323 4.83633 11.349 5.36933 11.349 6.106ZM7.8 13.048V11.228H9.308V13.048H7.8Z"
          fill="white"
        />
      </svg>
      <ReactTooltip className={styles.reactToolTip} />
    </span>
  );
};

interface TooltipProps {
  tip: string;
}

export default Tooltip;
