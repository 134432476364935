/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
import MainLayout from '@layouts/MainLayout';
import React from 'react';
import row1ShapesImage from '@assets/images/whatIsHapstar/Row1.svg';
import row2ShapesImage from '@assets/images/whatIsHapstar/Row2.svg';
import row3ShapesImage from '@assets/images/whatIsHapstar/Row3.svg';
import row4ShapesImage from '@assets/images/whatIsHapstar/Row4.svg';
import row5ShapesImage from '@assets/images/whatIsHapstar/Row5.svg';
import styles from './WhatIsHapstar.module.scss';

const WhatIsHapstar: React.FunctionComponent = () => {
  return (
    <MainLayout title="What is Hapstar" secondaryNavEnabled isEmployerPage>
      <div className={styles.container}>
        <div className={styles.whatIsHapstar}>
          <div>
            <h1>What is Hapstar?</h1>
          </div>

          <div style={{ marginTop: 45 }}>
            <h3 style={{ fontWeight: 600 }}>The Hapstar belief</h3>
            <div>
              <p style={{ color: 'black', marginTop: 28, fontSize: '18px' }}>
                “Everyone deserves to be happy, but sometimes we don’t know how”
              </p>
            </div>
            <div>
              <p style={{ color: 'black' }}>
                We’re all trying to be happier, more content, more satisfied with our lives, but there are so many barriers: social media, work pressure, high standards from society, difficult relationships, the list goes on.
              </p>

              <p style={{ color: 'black', marginTop: 28 }}>
                <b>So, what do we do?</b>
              </p>

              <p style={{ color: 'black' }}>
                We believe that the key to Happiness is to look inside, understand what makes up your life, what’s important to you, and focus on improving things, one at a time. It’s a journey, a process, something that you never fully achieve, but by doing it, you learn how to be happier every day.
              </p>

              <p style={{ color: 'black', marginTop: 28 }}>
                We created Hapstar to make that process easier. We work off the 5 core pillars of wellbeing – Mental, Physical, Social, Financial, and Career Wellbeing. These, if improved, will lead to overall wellbeing and contentment in life. Some may be more important to you than others, but by breaking health and happiness down into manageable chunks, we can remain focused and not be overwhelmed by the task.
              </p>

              <p style={{ color: 'black', marginTop: 28 }}>
                We’re here to support and guide you through this process. It’s simple and straight forward, but not easy, so don’t be hard on yourself, stay positive, and remember, all journeys start with a single step.
              </p>
            </div>
          </div>
        </div>
        <img src={row1ShapesImage} alt="" className={styles.rightPosition} />
        <div className={styles.flexRow}>
          <div>
            <img src={row2ShapesImage} alt="" className={styles.img} />
          </div>
          <div className={styles.marginLeft}>
            <h3 style={{ fontWeight: 600 }}>How does Hapstar work?</h3>
            <div>
              <p style={{ color: 'black', marginTop: 28, fontSize: 18 }}>
              Hapstar is simple, we guide you through a process of self-reflection, focus, and improvement, with lots of guidance and activities along the way.{' '}
              </p>
            </div>
            <div>
              <p style={{ color: 'black', marginTop: 28 }}>
                # Everything starts with a check-in. How are you feeling? What’s making you feel this way? Once we know this, we can start to work towards being happier and healthier.{' '}
              </p>
              <p style={{ color: 'black', marginTop: 28 }}>
                # Next comes the focus - what do we want to improve? We can’t change our lives all in one go, so let’s hone in on one area of wellbeing and do something about it.{' '}
               </p>
              <p style={{ color: 'black', marginTop: 28 }}>
                # Finally, your personalised plan. Hapstar will build a step-by-step process to work towards improved wellbeing, not just in your focus area, but in all aspects of life. Follow each step, stay accountable, and enjoy the incredible rewards.{' '}
              </p>
              <p style={{ color: 'black', marginTop: 28 }}>
                But wait, there’s more. Don’t forget to check out the Deep-Dives, Learning Zone and Wellbeing Hub for a deeper understanding of your wellbeing, content and activities, and all of the wellbeing initiatives and offerings provided by your company or organisation.{' '}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.marginTop}>
          <div className={styles.marginHorizontal}>
            <h3 style={{ fontWeight: 600 }}>Who is Hapstar for?</h3>
            <div>
              <p style={{ color: 'black', marginTop: 28, fontSize: 18 }}>
                Hapstar is for everyone.{' '}
              </p>
            </div>
            <div>
              <p style={{ color: 'black', marginTop: 28 }}>
                Like physical fitness, you don’t just train for it when you don’t have it. Often the fittest people are the ones that exercise the most. Happiness is the same. Train yourself to understand what practical actions make you feel better, and those actions will be there when you need them most. Maintaining positive balance in your life is a constant process, so don’t wait until you need it before you act.
              </p>
            </div>
          </div>
          <div className={styles.flexEnd}>
            <img src={row3ShapesImage} alt="" className={styles.img} />
          </div>
        </div>
        <div className={styles.flexRow}>
          <div>
            <img src={row4ShapesImage} alt="" className={styles.img} />
          </div>
          <div className={styles.marginLeft}>
            <h3 style={{ fontWeight: 600 }}>What can I expect from Hapstar?</h3>
            <div>
              <p style={{ color: 'black', marginTop: 28 }}>
                The goal is to be happier, healthier, and to live a better life, but that’s not always possible. Sometimes your happiness will be lower, through no fault of your own. Life can be tough, and sometimes things get us down. However, Hapstar is there to help you navigate through these challenges, learning how to help yourself in all situations and giving you the tools to bring yourself back up again.
              </p>

              <p style={{ color: 'black', marginTop: 28 }}>
                The goal of Hapstar is for you to not need Hapstar. With enough practise, you’ll automatically run the Hapstar journey subconsciously and always be on the lookout for ways to make yourself happy.
              </p>

              <h4 style={{ fontWeight: 600 }}>Good luck!</h4>

              <p style={{ color: 'black', marginTop: 28, fontSize: 18 }}>
                Enjoy the process, the reward, and the small steps towards happiness.
              </p>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default WhatIsHapstar;
