import React from 'react';
import styles from './index.module.scss';

const EmployerCategoryScoreCard: React.FunctionComponent<NewHomeCategoryScoreCardProps> = (
  props,
) => {
  const { category, score } = props;

  return (
    <div className={styles.categoryScoreCard}>
      <div className={styles.categoryContainer}>
        <div className={styles.categoryText}>{category}</div>
        <div className={styles.scoreText}>{score.toFixed(0)}</div>
      </div>
    </div>
  );
};

type NewHomeCategoryScoreCardProps = {
  category: string;
  score: number;
};

export default EmployerCategoryScoreCard;
