import {
  getEmployeeHighlightsData,
  getEmployeerSnapshot,
  getFilteredResults,
  getFilters,
  getHistoricalEmployerGraphData,
} from '@api/Employer';
import { getQuestionnairesNames } from '@api/questionnaires';
import isEmpty from 'lodash/isEmpty';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import LoadingPage from '@components/LoadingPage/LoadingPage';
import Button from '@components/Button';
import EmployerArea from '@components/Charts/EmployerArea';
import EmployerHomeGauge from '@components/Charts/Gauge/employerHomeGauge';
import RadarEmployer from '@components/Charts/RadarEmployer';
import CircleScoreCard from '@components/CircleScoreCard';
import SliceAndDiceFilters from '@components/SliceAndDiceFilters';
import EmployerCategoryScoreCard from '@components/EmployerCategoryScoreCard';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import MainLayout from '@layouts/MainLayout';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Checkbox from '@components/Checkbox';
import { Grid } from '@mui/material';
import OldEmployerAreaChart from '@components/Charts/OldEmployerAreaChart';
import OldSliceAndDiceFilters from '@components/OldSliceAndDiceFilters';
import usersIcon from '../assets/images/icons/users.svg';
import smile from '../assets/images/icons/smile.svg';
import tray from '../assets/images/icons/inbox.svg';
import star from '../assets/images/icons/Subtract.svg';
import highestDriver from '../assets/images/icons/Highest_driver.svg';
import styles from './EmployerHomepage.module.scss';

const HighlightCard = ({ title, value, icon, index }: any) => {
  const icons = [
    { name: 'smile', icon: smile },
    { name: 'tray', icon: tray },
    { name: 'person', icon: usersIcon },
    { name: 'star', icon: star },
  ];
  const iconData = icons.find((i) => i.name === icon);

  return (
    <div key={JSON.stringify(index)} className={styles.highlightCard}>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
        <img
          src={index === 4 ? highestDriver : index === 5 ? highestDriver : iconData?.icon}
          alt="users icons"
        />
      </div>
      <div
        style={{
          borderRadius: '50%',
          border: '13px solid #3BE1CE',
          marginTop: 20,
          height: 150,
          width: 150,
          padding: 40,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: '600',
          textAlign: 'center',
          fontSize: 24,
        }}
      >
        {value}
      </div>
      <p
        style={{
          marginTop: 30,
          fontSize: 20,
          fontWeight: '600',
          color: '#505050',
          width: '160px',
          textAlign: 'center',
        }}
      >
        {title}
      </p>
    </div>
  );
};

const objectShape = yup
  .object()
  .shape({
    label: yup.string(),
    value: yup.string(),
  })
  .nullable();

const filterValidationSchema = yup.object().shape({
  locations: objectShape,
  teams: objectShape,
  gender: objectShape,
  seniority: objectShape,
  jobTypes: objectShape,
  jobTitles: objectShape,
  peopleLead: objectShape,
  active: objectShape,
});

const removeNullUndefined = (dat: any) =>
  Object.entries(dat)
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

const EmployerHomepage = () => {
  const [snapshotData, setSnapshotData] = useState([]);
  const [filtered, setFiltered] = useState(false);
  const [selectedCard, setSelectedCard] = useState<any>(1);
  const [graphData, setGraphData] = useState<any>([]);
  const [highlightsData, setHighlightsData] = useState<any>([]);
  const [questionnaireNames, setQuestionnaireNames] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<any>(0);
  const [checkboxValue, setCheckboxValue] = useState<any>(true);
  const [checkboxValue1, setCheckboxValue1] = useState<any>(true);
  const [checkboxValue2, setCheckboxValue2] = useState<any>(true);
  const [checkboxValue3, setCheckboxValue3] = useState<boolean>(true);
  const [checkboxValue4, setCheckboxValue4] = useState<boolean>(true);
  const [checkboxValue5, setCheckboxValue5] = useState<boolean>(true);
  const [months, setMonths] = useState<any>(1);
  const { activeSubNav, toggleSubNav } = useContext(SecondaryNavigationContext);
  const [resultsScoreStars, setResultsScoresStars] = useState<any>([]);
  const [resultsData, setResultsData] = useState<any>([]);
  const [filters, setFilters] = useState<any>([]);
  const [selectedTabs, setSelectedTabs] = useState<any>([0, 1, 2]);
  const [error, setError] = useState(false);
  const questionnaireIds = useMemo(
    () =>
      [
        checkboxValue === true ? 1 : false,
        checkboxValue1 === true ? 2 : false,
        checkboxValue2 === true ? 3 : false,
        checkboxValue3 === true ? 5 : false,
        checkboxValue4 === true ? 6 : false,
        checkboxValue5 === true ? 7 : false,
      ].filter((i: any) => i !== false),
    [checkboxValue, checkboxValue1, checkboxValue2, checkboxValue3, checkboxValue4, checkboxValue5],
  );

  const filterMethods = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    resolver: yupResolver(filterValidationSchema),
  });

  useEffect(() => {
    filterMethods.setValue('active', { label: 'Active Users', value: 'true' });
  }, []);

  /**
   * Fetch initial snapshot data
   */
  useEffect(() => {
    const getSnapshotData = async () => {
      const data = await getEmployeerSnapshot({});
      setSnapshotData(data);
    };

    getSnapshotData();
  }, []);

  useEffect(() => {
    getQuestionnairesNames().then((data) => {
      setQuestionnaireNames(data);
    });
  }, []);

  /**
   * Fetch the historical Employer graph data
   */
  useEffect(() => {
    getHistoricalEmployerGraphData({
      questionnaire_ids: [1, 2, 3, 5, 6, 7],
      months,
    }).then((data) => {
      setGraphData(data);
    });
  }, [questionnaireIds, months]);

  /**
   * Transform the filter values to the right structure for the payload
   * Strip the null and undefined values as required by the API
   */
  const getFilterValues = useCallback(() => {
    const values = filterMethods.getValues();
    const mapField = (field: string) => {
      const value = values?.[field]?.value;
      if (value === undefined) return null;

      if (
        field === 'locations' ||
        field === 'teams' ||
        field === 'jobTypes' ||
        field === 'jobTitles'
      ) {
        return parseInt(value, 10) || null;
      }

      if (field === 'gender' || field === 'seniority') {
        if (value === '') return null;
      }

      if (field === 'peopleLead' || field === 'active') {
        if (value === 'true') return true;
        if (value === 'false') return false;

        return null;
      }

      return value;
    };

    const mappedValues = {
      locations: mapField('locations'),
      teams: mapField('teams'),
      gender: mapField('gender'),
      seniority: mapField('seniority'),
      jobTypes: mapField('jobTypes'),
      jobTitles: mapField('jobTitles'),
      peopleLead: mapField('peopleLead'),
      active: mapField('active'),
    };

    return removeNullUndefined(mappedValues);
  }, [filterMethods]);

  const handleChangeCompany = (e: any, index: any) => {
    if (index === 0) {
      toggleSubNav({ id: 1, name: 'Overall' });
      setCheckboxValue((prev: any) => !prev);
    } else if (index === 1) {
      toggleSubNav({ id: 2, name: 'Career' });
      setCheckboxValue1((prev: any) => !prev);
    } else if (index === 2) {
      toggleSubNav({ id: 3, name: 'Mental' });
      setCheckboxValue2((prev: any) => !prev);
    } else if (index === 3) {
      toggleSubNav({ id: 5, name: 'Physical' });
      setCheckboxValue3((prev: any) => !prev);
    } else if (index === 4) {
      toggleSubNav({ id: 6, name: 'Financial' });
      setCheckboxValue4((prev: any) => !prev);
    } else if (index === 5) {
      toggleSubNav({ id: 7, name: 'Social' });
      setCheckboxValue5((prev: any) => !prev);
    }
  };

  /**
   * Get the scores for the results and get the average scores
   */
  const getAverageScores = useCallback((data: any = {}) => {
    const getScores = async () => {
      const [data1, data2, data3] = await Promise.all([
        getFilteredResults(1, data),
        getFilteredResults(2, data),
        getFilteredResults(3, data),
      ]);

      setResultsScoresStars([data1, data2, data3]);
    };

    getScores();
  }, []);

  /**
   * Gets the filtered results and highlights data and recalculate the average results
   */
  const handleFilterResultsData = useCallback(() => {
    const getFilteredResultsHighlightsData = async () => {
      const filterValues = getFilterValues();
      const filteredResultsResponse = await getFilteredResults(selectedCard, filterValues);
      const highlightsResponse = await getEmployeeHighlightsData(filterValues);

      const { categories } = filteredResultsResponse;

      const scoresData = categories?.map((cat: any) => {
        // If there is only one question create an array of 4 of the same values for the chart data
        if (cat.questions.length === 1) {
          return new Array(4).fill(cat.questions[0].score.toFixed(0));
        }

        // Otherwise return the scores
        return cat.questions.map((question: any) => question.score.toFixed(0));
      });

      getAverageScores(filterValues);

      const newStructure = {
        ...filteredResultsResponse,
        categories: filteredResultsResponse?.categories.map((i: any, key: any) => ({
          ...i,
          data: scoresData[key],
        })),
      };

      setHighlightsData(highlightsResponse);
      setResultsData(newStructure);
    };

    getFilteredResultsHighlightsData();
  }, [selectedCard, getFilterValues, getAverageScores]);

  /**
   * Apply filters for highlights and highlights data
   */
  const handleApplyFilters = React.useCallback(
    (data: any) => {
      handleFilterResultsData();
      setFiltered(true);
    },
    [handleFilterResultsData],
  );

  /**
   * Fetch the initial results and get the average scores
   */
  useEffect(() => {
    getAverageScores();
  }, [getAverageScores]);

  /**
   * Retrieves the filter options data
   */
  useEffect(() => {
    const getFiltersData = async () => {
      const data = await getFilters();
      setFilters(data);
    };

    getFiltersData();
  }, []);

  /**
   * Fetch the initial data
   */
  useEffect(() => {
    handleFilterResultsData();
  }, [handleFilterResultsData]);

  /**
   * Reset the form and re-fetch the initial data
   */
  const handleResetFilter = useCallback(() => {
    // Reset the form
    filterMethods.reset();

    // Re-fetch the initial data
    filterMethods.setValue('active', { label: 'Active Users', value: 'true' });
    handleFilterResultsData();
    setFiltered(false);

    // Reset the tab
    toggleSubNav({ id: 1, name: 'Life Star' });
    setSelectedCard(1);
  }, [filterMethods, handleFilterResultsData, toggleSubNav]);

  const isLoading = isEmpty(snapshotData) || isEmpty(graphData);

  if (error) {
    return <p>Unexpected Error</p>;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <MainLayout title="Employer Portal" useEmployerNav isEmployerPage secondaryNavEnabled>
      <div>
        <div className={styles.mainContainer}>
          <h2 className={styles.mainTitle}>Organisation Snapshot</h2>
          <div className="container">
            <Grid container spacing={2}>
              {snapshotData?.length > 0 &&
                snapshotData.map((i: any, key: number) => (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    key={JSON.stringify(key + i)}
                    className={styles.snapshotCardContainer}
                  >
                    <div className={styles.snapshotCard}>
                      <h2 className={styles.snapshotTitle}>{i.title}</h2>
                      <div>
                        {i.averageScore ? (
                          <EmployerHomeGauge score={i.averageScore} />
                        ) : (
                          <div className={styles.emptyDataBox}>
                            <div className={styles.emptyCircle}>
                              <p className={styles.emptyText}>NO DATA</p>
                            </div>
                          </div>
                        )}
                      </div>

                      <div>
                        {i.categories?.length > 0 ? (
                          i.categories?.map((categoryScore: any, index: number) => (
                            <EmployerCategoryScoreCard
                              key={JSON.stringify(categoryScore.id + index)}
                              category={categoryScore.name}
                              score={categoryScore.score}
                            />
                          ))
                        ) : (
                          <div
                            style={{
                              fontSize: 30,
                              display: 'flex',
                              justifyContent: 'center',
                              width: '100%',
                              color: 'black',
                            }}
                          >
                            NO DATA
                          </div>
                        )}
                      </div>
                      <div style={{ marginTop: '15px', textAlign: 'center' }}>
                        <Button type="primary" isButton>
                          Set star
                        </Button>
                      </div>
                    </div>
                  </Grid>
                ))}
            </Grid>
          </div>

          <div className={styles.questionairesContainer}>
            {questionnaireNames &&
              questionnaireNames.map((i: any, key: any) => (
                <div key={JSON.stringify(key + i)} className={styles.questionaireItem}>
                  <Checkbox
                    id={i.name}
                    text={i.name}
                    color={
                      key === 0
                        ? '#FCD300'
                        : key === 1
                        ? '#019DF2'
                        : key === 2
                        ? '#06BF97'
                        : key === 3
                        ? '#BEAAF3'
                        : key === 4
                        ? '#FF8A4A'
                        : '#8EEA36'
                    }
                    onChange={(e: any) => {
                      handleChangeCompany(e, key);

                      if (selectedTabs.includes(key)) {
                        setSelectedTabs((prev: any) => prev.filter((tab: any) => tab !== key));
                      } else {
                        setSelectedTabs([...selectedTabs, key]);
                      }
                    }}
                    checked={
                      key === 0
                        ? checkboxValue
                        : key === 1
                        ? checkboxValue1
                        : key === 2
                        ? checkboxValue2
                        : key === 3
                        ? checkboxValue3
                        : key === 4
                        ? checkboxValue4
                        : checkboxValue5
                    }
                  />
                </div>
              ))}
          </div>

          <div className={styles.timeframes}>
            {['1 month', '6 month', '1 year'].map((i: any, key: any) => {
              const selected = key === selectedItem;

              return (
                <button
                  type="button"
                  key={JSON.stringify(i + key)}
                  onClick={() => {
                    setSelectedItem(key);
                    if (key === 0) {
                      setMonths(1);
                    }

                    if (key === 1) {
                      setMonths(6);
                    }

                    if (key === 2) {
                      setMonths(12);
                    }
                  }}
                  style={{
                    border: '3px solid #069B7B',
                    backgroundColor: selected ? '#069B7B' : 'white',
                    borderRadius: 30,
                    padding: '5px 20px',
                    width: '10rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight:
                      key !== ['1 month', '6 month', '1 year'].length - 1 ? 10 : undefined,
                  }}
                >
                  <p
                    style={{
                      color: selected ? 'white' : '#069B7B',
                      fontSize: 12,
                      margin: 0,
                    }}
                  >
                    {i}
                  </p>
                </button>
              );
            })}
          </div>

          {/* Graph */}
          <OldEmployerAreaChart
            values={graphData}
            selectedIndex={0}
            stars={[]}
            months={months}
            selectedTimeframeKey={selectedItem}
            selectedTabs={selectedTabs}
          />
        </div>
      </div>

      <div className={styles.resultsContainer}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: 20,
          }}
        >
          <h2 style={{ color: 'white' }}>Slice and dice</h2>
        </div>

        {filters && filters.length > 0 && (
          <OldSliceAndDiceFilters
            methods={filterMethods}
            isFiltered={filtered}
            onFilter={handleApplyFilters}
            onReset={handleResetFilter}
            jobTitles={filters[5].jobTitles}
            jobTypes={filters[4].jobTypes}
            locations={filters[0].locations}
            teams={filters[1].teams}
          />
        )}

        <div>
          <h2 style={{ color: 'white', margin: '20px 0' }}>Highlights</h2>
          <div className={styles.highlightsContainer}>
            {highlightsData &&
              highlightsData.map((i: any, key: any) => (
                <HighlightCard
                  key={JSON.stringify(i + key)}
                  title={i.title}
                  value={i.value}
                  icon={i.icon}
                  index={key}
                />
              ))}
          </div>
        </div>
        <h2 style={{ color: 'white', margin: '20px 0' }}>Results</h2>
        <div
          style={{
            backgroundColor: 'transparent',
            height: 350,
            width: '100%',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                height: 25,
                width: selectedCard === 3 ? '100%' : '40%',
              }}
            />
          </div>
          <div
            style={{
              height: 200,
              backgroundColor: '#019DF2',
              width: '100%',
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              borderRadius: '30px 0 0 0',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
            }}
          >
            <div
              onClick={() => {
                toggleSubNav({ id: 1, name: 'Life Star' });
                setSelectedCard(1);
              }}
              style={{
                backgroundColor: selectedCard === 1 ? 'white' : '#019DF2',
                width: 'calc(100% / 3)',
                border: 0,
                display: 'flex',
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                height: '350px',
                flexDirection: 'column',
                borderRadius:
                  selectedCard === 1
                    ? '30px 30px 0 0'
                    : selectedCard === 3
                    ? '30px 0 0 0'
                    : '30px 30px 30px 0',
              }}
            >
              <EmployerHomeGauge
                score={resultsScoreStars?.[0]?.questionnaireAverageScore}
                color={selectedCard === 1 ? '#505050' : 'white'}
              />
              <h2
                style={{
                  color: selectedCard === 1 ? 'black' : 'white',
                }}
                className={styles.resultsItemTitle}
              >
                {resultsScoreStars?.[0]?.questionnaireName}
              </h2>
            </div>
            <div
              onClick={() => {
                toggleSubNav({ id: 2, name: 'Career Star' });
                setSelectedCard(2);
              }}
              style={{
                backgroundColor: selectedCard === 2 ? 'white' : '#019DF2',
                width: 'calc(100% / 3)',
                height: '350px',
                border: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                flexDirection: 'column',
                borderRadius:
                  selectedCard === 2
                    ? '30px 30px 0 0'
                    : selectedCard === 3
                    ? '30px 30px 30px 0'
                    : '30px 30px 0 30px',
              }}
            >
              <EmployerHomeGauge
                score={resultsScoreStars?.[1]?.questionnaireAverageScore}
                color={selectedCard === 2 ? '#505050' : 'white'}
              />
              <h2
                style={{
                  color: selectedCard === 2 ? 'black' : 'white',
                }}
                className={styles.resultsItemTitle}
              >
                {resultsScoreStars?.[1]?.questionnaireName}
              </h2>
            </div>
            <div
              onClick={() => {
                setSelectedCard(3);
                toggleSubNav({ id: 3, name: 'Wellness Star' });
              }}
              style={{
                backgroundColor: selectedCard === 3 ? 'white' : '#019DF2',
                cursor: 'pointer',
                width: 'calc(100% / 3)',
                height: '350px',
                display: 'flex',
                border: 0,
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius:
                  selectedCard === 3
                    ? '30px 30px 30px 30px'
                    : selectedCard === 2
                    ? '30px 30px 0 30px'
                    : '30px 30px 0 0',
              }}
            >
              <EmployerHomeGauge
                score={resultsScoreStars?.[2]?.questionnaireAverageScore}
                color={selectedCard === 3 ? '#505050' : 'white'}
              />
              <h2
                style={{
                  color: selectedCard === 3 ? 'black' : 'white',
                }}
                className={styles.resultsItemTitle}
              >
                {resultsScoreStars?.[2]?.questionnaireName}
              </h2>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: 'white',
            borderRadius:
              selectedCard === 1
                ? '0 30px 30px 30px'
                : selectedCard === 2
                ? '30px'
                : '30px 0 30px 30px',
            paddingTop: 80,
          }}
        >
          <div className="row">
            {resultsData &&
              resultsData.categories?.map((radar: any, index: any) => (
                <div
                  key={JSON.stringify(radar + index)}
                  className="col-12 col-sm-12 col-md-6 col-lg-4"
                >
                  <RadarEmployer {...radar} showOnScorePagea />

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '22px',
                      marginBottom: '100px',
                    }}
                  >
                    {radar.questions.map((score: any, scoreIndex: any) => (
                      <CircleScoreCard
                        index={score.id}
                        key={JSON.stringify(scoreIndex + index)}
                        text={score.name}
                        color={score.color}
                        score={score.score}
                        isSelected={score.isSelected}
                        scoreDifference={score.scoreDifference}
                      />
                    ))}
                  </div>
                </div>
              ))}
            {resultsData?.categories?.length < 1 && (
              <h1
                style={{
                  color: 'black',
                  fontSize: 50,
                  fontWeight: '600',
                  textAlign: 'center',
                  height: '100vh',
                }}
              >
                NO DATA
              </h1>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default EmployerHomepage;
