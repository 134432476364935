/* eslint-disable prettier/prettier */
import { PaginationInterface, ThoughtDataInterface } from 'custom';
import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const getAllEmployees = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  if (response.status === 401) {
    return 401;
  }

  const result: any = await response.json();

  return result;
};

export const getEmployeerSnapshot = async (data: any) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/employer/new/snapshot`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(data),
  });

  if (response.status === 401) {
    return 401;
  }

  const result: any = await response.json();

  return result;
};

export const getHistoricalEmployeerData = async (questionnaireId = 2) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/employer/new/star-history?questionnaireid=${questionnaireId}`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  if (response.status === 401) {
    return 401;
  }

  const result: any = await response.json();

  return result;
};

export const getHistoricalEmployerGraphData = async (data: any) => {
  const result = await fetch(`${process.env.REACT_APP_API_URL}/employer/new/star-history`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(data),
  });

  const result1: any = await result.json();

  return result1;
};

export const getEmployeeHighlightsData = async (data: any) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/employer/new/highlights`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(data),
  });

  if (response.status === 401) {
    return 401;
  }

  const result: any = await response.json();

  return result;
};

export const getEmployeeHomePageCardsData = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/summary`, {
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  if (response.status === 401) {
    return 401;
  }

  const result: any = await response.json();

  return result;
};

export const getEmployeeHighlights = async (months = 12) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/employer/new/thoughts-highlights?months=${months}`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  if (response.status === 401) {
    return 401;
  }

  const result: any = await response.json();

  return result;
};

export const getHappiestDriver = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/happiest-driver`, {
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  if (response.status === 401) {
    return 401;
  }

  const result: any = await response.json();

  return result;
};

export const getFilters = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/employer/new/filters`, {
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  if (response.status === 401) {
    return 401;
  }

  const result: any = await response.json();

  return result;
};

export const getEmployerThoughts = async (
  responded: boolean,
  questionnaireIds: any,
  page: number,
  perPage: number,
  order: string,
  orderBy: string,
) => {
  const payload = { responded, questionnaireIds };
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}/employer/new/thoughts?page=${page}&perPage=${perPage}&order=${order}&orderBy=${orderBy}`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(payload),
    },
  );

  const result1: { data: ThoughtDataInterface[]; pagination: PaginationInterface } =
    await result.json();

  return result1;
};

export const getFilteredResults = async (questionnaireId: any, data: any) => {
  const result = await fetch(`${process.env.REACT_APP_API_URL}/employer/new/filtered-results`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify({ ...data, questionnaireId }),
  });

  const result1: any = await result.json();

  return result1;
};

export const getEmployerResponses = async (data: any) => {
  const result = await fetch(`${process.env.REACT_APP_API_URL}/employer/new/responses`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(data),
  });

  const result1: any = await result.json();

  return result1;
};

export const saveEmployerResponses = async (data: any) => {
  await fetch(`${process.env.REACT_APP_API_URL}/employer/new/save-responses`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    body: JSON.stringify(data),
  });
};

export const getSpeakUpResponsesForEmployer = async (
  responded: boolean,
  questionnaireIds: number[],
  page: number,
  perPage: number,
  order: string,
) => {
  const formattedReq = {
    responded,
    questionnaireIds,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/employer/new/instant-help-speak-ups?page=${page}&perPage=${perPage}&order=${order}`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  if (!response.ok) {
    if (response.status === 401) {
      return 401; // Unauthorized
    }

    throw new Error(`Failed to fetch help and speakup responses: ${response.statusText}`);
  }

  return response.json();
};

export const saveSpeakupResponse = async (instantHelpSpeakUpId: number, message: string) => {
  const formattedReq = {
    instantHelpSpeakUpId,
    message,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/employer/new/instant-help-speak-ups/responses`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(formattedReq),
    },
  );

  if (!response.ok) {
    if (response.status === 401) {
      return 401; // Unauthorized
    }

    throw new Error(`Failed to save help and speakup response: ${response.statusText}`);
  }

  return response.json();
};

export const getWellbeingScoreHps = async (data: any) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/employer/new/get-wellbeing-score-hps`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(data),
    },
  );

  const result: { wellbeingScore: number; totalUsers: number } = await response.json();

  return result;
};

export const getShortStarQuestionScoreHps = async (data: any) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/employer/new/get-short-question-score-hps`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    const error = new Error(`Failed to fetch score hps: ${response?.statusText}`);
    throw error;
  }

  const result: { questionId: number; question: string; scoreAverage: number }[] =
    await response.json();

  return result;
};

export const getFilteredWellbeingScoreHps = async (data: any) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/employer/new/get-filtered-wellbeing-score-hps`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(data),
    },
  );

  const result: { wellbeingScore: number; totalUsers: number } = await response.json();

  return result;
};

export const getFilteredShortStarQuestionScoreHps = async (data: any) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/employer/new/get-filtered-short-question-score-hps`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    const error = new Error(`Failed to fetch score hps: ${response?.statusText}`);
    throw error;
  }

  const result: { questionId: number; question: string; scoreAverage: number }[] =
    await response.json();

  return result;
};

export const getBoostersAndDrainers = async (data: any) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/employer/new/get-boosters-and-drainers`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(data),
    },
  );

  const result: BoostersAndDrainersInterfaceData = await response.json();

  return result;
};

export const getEmployerResourceUsage = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/employer/new/get-resources-usage`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('Failed to get data. Unauthorized!');
    }

    throw new Error(`Failed to fetch resource usage: ${response}`);
  }

  const result: ResourceUsageInterface = await response.json();

  return result;
};

interface ResourceUsageInterface {
  actions: {
    actionsSet: number;
    actionsCompleted: number;
  };
  learningAndCoaching: {
    hoursOfLearning: number;
    coachingSessions: number;
  };
  wellbeingHub: {
    resourcesAccessed: number;
  };
  earlyWarning: {
    urgentHelpAccessed: number;
    speakUps: number;
  };
}

interface Booster {
  questionId: number;
  name: string;
  score: number;
}

interface BoostersAndDrainersInterfaceData {
  topBoosters: Booster[];
  topDrainers: Booster[];
  topCareerBoosters: Booster[];
  topCareerDrainers: Booster[];
}
