import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

const padTo2Digits = (num: number) => num.toString().padStart(2, '0');

export const formatDate = (stringTimeStamp: string) => {
  const date = new Date(parseInt(stringTimeStamp, 10) * 1000);

  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join(
    '/',
  );
};

export const newFormatDate = (stringTimeStamp: string) => {
  const date = stringTimeStamp.slice(0, 10);
  const temp = date.split('-');
  const to = `${temp[2]}.${temp[1]}.${temp[0]}`;

  return to;
};

export const calculateDate = (date: string) => {
  dayjs.extend(relativeTime);

  return dayjs(date).fromNow();
};

export function formatDateStringSplitDots(inputDateString: string, locale = 'en-GB'): string {
  const date = new Date(inputDateString);

  const day = date.toLocaleDateString(locale, { day: '2-digit' });
  const month = date.toLocaleDateString(locale, { month: '2-digit' });
  const year = date.toLocaleDateString(locale, { year: 'numeric' });

  const formattedDate = `${day}.${month}.${year}`;

  return formattedDate;
}

export const formatDateToDateMonthYear = (dateString: string) => {
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  const date = new Date(dateString);

  return date.toLocaleDateString('en-GB', options as Intl.DateTimeFormatOptions);
};
