import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import useWindowDimensions from '@hooks/useWindowDimensions';
import chartBG from '@assets/images/chart-bg.svg';
import Gauge from '.';

const EmployerHomeGauge: React.FunctionComponent<GaugeProps> = (props) => {
  const { score, color } = props;
  const series = [(Math.round(score) + 100) / 2]; //  converting to 0-100 range for chart
  const display = Math.round(score);
  const { isDesktop } = useWindowDimensions();

  const options: ApexOptions = {
    chart: {
      height: 250,
      type: 'radialBar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -360,
        endAngle: 0,
        hollow: {
          margin: 0,
          size: '62%',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: false,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: '#FFFFFF',
          strokeWidth: '100%',
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: false,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: 30,
            show: false,
            color: '#888',
            fontSize: '17px',
          },
          value: {
            formatter(val: any): any {
              return `${String(display.toFixed(0))}`;
            },
            color,
            fontSize: isDesktop ? '34px' : '20px',
            fontFamily: 'Titillium Web',
            show: true,
            offsetY: 10,
          },
        },
      },
    },
    fill: {
      type: 'image',
      image: {
        src: [chartBG],
      },
    },
    stroke: {
      lineCap: 'round',
    },
    labels: [''],
  };

  return (
    <div id="chart">
      <Chart options={options} series={series} type="radialBar" height={isDesktop ? 250 : 150} />
    </div>
  );
};

EmployerHomeGauge.defaultProps = {
  color: '#505050',
};

interface GaugeProps {
  score: number;
  color?: string;
}

export default EmployerHomeGauge;
