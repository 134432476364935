import React from 'react';
import ScoreButtonsMobile from '@components/ScoreButtonsMobile';

import { CreateStarQuestionInterface } from '@interfaces/index';
import Tooltip from '@components/Tooltip';
import Button from '@components/Button';
import { useProSidebar } from 'react-pro-sidebar';
import useWindowDimensions from '@hooks/useWindowDimensions';
import styles from './index.module.scss';

const QuestionItemContentMobile: React.FunctionComponent<QuestionItemContentProps> = (props) => {
  const { question, onChange, onSidebarOpen } = props;
  const { collapseSidebar } = useProSidebar();
  const { isMobile } = useWindowDimensions();

  const handleClick = () => {
    collapseSidebar(false);
    onSidebarOpen(question.score, question.color, question.name, question.id);
  };

  const isButtonsDisabled = () => {
    let isDisabled = true;
    if (question.score > 0) {
      isDisabled = false;
    }

    return isDisabled;
  };

  return (
    <div className={styles.hsSingleQuestion}>
      <ScoreButtonsMobile
        onChange={onChange}
        name={question.name}
        questionId={question.id}
        currentScore={question.score}
      />
    </div>
  );
};

interface QuestionItemContentProps {
  question: CreateStarQuestionInterface;
  onChange: (score: number, position: number) => void;
  onSidebarOpen: (score: number, color: string, question: string, id: number) => void;
}

export default QuestionItemContentMobile;
