import { saveQuestions } from '@api/shortStar';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import ShortStarSlider from '@components/Molecules/ShortStar/ShortStarSlider';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import {
  ShortStarQuestionsInterface,
  ShortStatContentTypes,
  isIOS,
  updateUserStatusRequest,
} from 'custom.d';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { RootState } from 'store';
import { shortStarWizardActions } from 'store/Slices/ShortStarWizardSlice';
import { updateUserStatusData } from 'store/Actions/UserStatusAction';
import styles from './index.module.scss';

const Questions = ({ handleStep, data }: QuestionsProps) => {
  const dispatch = useAppDispatch();
  const [sliderValues, setSliderValues] = useState<SliderValues>({});
  const wizardData = useAppSelector((state: RootState) => state.shortStarWizard);
  const [changeDetected, setChangedDetected] = useState<boolean>(false);
  const userStatus = useAppSelector((st: RootState) => st.userStatus.data);

  useEffect(() => {
    setSliderValues(wizardData.sliderStates);
  }, [wizardData.sliderStates]);

  useEffect(() => {
    const defaultSliderValues: SliderValues = {};
    data
      ?.filter((dt) => !dt.generalHappinessQuestion)
      .forEach((item: ShortStarQuestionsInterface) => {
        defaultSliderValues[item.questionId] = {
          questionId: item.questionId,
          questionText: item.questionText,
          score: 7,
        };
      });
    setSliderValues(defaultSliderValues);
  }, []);

  const updateUserStatus = async () => {
    const req: updateUserStatusRequest = {
      is_first_short_star_completed: true,
    };

    dispatch(updateUserStatusData(req));
  };

  const handleSliderChange = (
    event: Event,
    newValue: number,
    itemId: number,
    questionText: string,
  ) => {
    setChangedDetected(true);
    const modifiedData = (prevValues: SliderValues) => ({
      ...prevValues,
      [itemId]: {
        questionId: itemId,
        questionText,
        score: newValue,
      },
    });

    const newSliderValues = modifiedData(sliderValues);

    setSliderValues(newSliderValues);
    dispatch(shortStarWizardActions.storeHappinessSliderValues(newSliderValues));
  };

  const saveQuestionScores = async () => {
    const dataArray = await Object.entries(sliderValues)
      .map(([questionId, score]) => {
        const parsedQuestionId = parseInt(questionId, 10);
        if (Number.isNaN(parsedQuestionId)) {
          return null;
        }

        return {
          questionId: parsedQuestionId,
          score: score.score,
        };
      })
      .filter(Boolean);

    if (wizardData.generalHappinessQuestion) {
      await dataArray.push({
        questionId: wizardData.generalHappinessQuestion.questionId ?? 0,
        score: wizardData.generalHappinessQuestion.score ?? 0,
      });
    }

    const dataArrayFiltered = dataArray
      .filter((item) => item !== null)
      .map((item) => ({
        questionId: item!.questionId,
        score: Number(item!.score),
      }));

    const response = await saveQuestions(dataArrayFiltered, 4);

    if (response.success) {
      await dispatch(shortStarWizardActions.addQuestionnaireResponse(response.data));
    }

    const filledData: QuestionsScores[] = dataArrayFiltered
      .map(({ questionId, score }) => {
        const matchingItem = data?.find((item) => item.questionId === questionId);
        if (!matchingItem) {
          throw new Error(`No matching item found for questionId ${questionId} in data array.`);
        }

        return {
          questionId,
          questionInfo: matchingItem.questionInfo,
          questionText: matchingItem.questionText,
          colorTheme: matchingItem.colorTheme,
          generalHappinessQuestion: matchingItem.generalHappinessQuestion,
          icon: matchingItem.icon,
          questionnaireId: matchingItem.questionnaireId,
          score,
        };
      })
      .sort((a, b) => a.questionId - b.questionId);

    if (filledData?.length > 0) {
      await dispatch(shortStarWizardActions.addQuestionsScores(filledData));
    }

    await handleStep('REFLECTION_SECTION');
  };

  return (
    <>
      <h2 className={styles.modalTitle}>What’s making you feel this way?</h2>

      <div className={styles.sidesTextContainer}>
        <div className={styles.textLeft}>Very Unhappy</div>
        <div className={styles.textRight}>Very Happy</div>
      </div>

      {data
        ?.filter((dt) => !dt.generalHappinessQuestion)
        ?.map((item: ShortStarQuestionsInterface, index: number) => (
          <div key={index} className={styles.happinessSliderItem}>
            <ShortStarSlider
              sliderTopic={item.questionText}
              defaultValue={7}
              step={1}
              marks
              minValue={1}
              maxValue={10}
              sliderValue={sliderValues[item.questionId]?.score || 7}
              handleSliderChange={(event: Event, newValue: number) => {
                if (isIOS && event.type === 'mousedown') {
                  return;
                }

                handleSliderChange(event, newValue, item.questionId, item.questionText);
              }}
              valueLabel="auto"
            />
          </div>
        ))}

      <div className={`${styles.continueBtnContainer} ${styles.btnFade}`}>
        <DefaultButton
          variant="primary"
          type="button"
          className={styles.continueBtnPrimary}
          onClick={() => {
            saveQuestionScores();
          }}
          disabled={!changeDetected}
        >
          Continue
        </DefaultButton>
      </div>
    </>
  );
};

Questions.defaultProps = {};

interface QuestionsProps {
  handleStep: Dispatch<SetStateAction<ShortStatContentTypes>>;
  data: ShortStarQuestionsInterface[] | undefined | null;
}

interface SliderValues {
  [key: number]: {
    questionId: number;
    questionText: string;
    score: number;
  };
}

interface QuestionsScores {
  questionId: number;
  questionInfo: string;
  questionText: string;
  colorTheme: string;
  generalHappinessQuestion: boolean;
  icon: string;
  questionnaireId: number;
  score: number;
}

export default Questions;
