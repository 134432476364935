import { getIpAddress } from '@api/auth/login';
import { logActionAlertEmail } from '@api/notifications';
import { getShortStarAssessmentScores } from '@api/shortStar';
import ShortStarModal from '@components/Organisms/ShortStarModal';
import Seo from '@components/SEO';
import ThemeContextProvider from '@context/ThemeContext';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import useGoNative from '@hooks/useGoNative';
import useWindowDimensions from '@hooks/useWindowDimensions';
import MobileFooterEmployer from '@layouts/MainLayout/MobileFooterEmployer';
import { LogTypes, TwitterCardType } from 'custom.d';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { useHistory } from 'react-router';
import { ToastContainer } from 'react-toastify';
import { RootState } from 'store';
import { configurationSliceActions } from 'store/Slices/configurationsSlice';
import Footer from './Footer';
import Header from './Header';
import MobileFooter from './MobileFooter';
import SecondaryNavigation from './SecondaryNavigation';
import Sidenav from './Sidenav';
import styles from './index.module.scss';

const MainLayout: React.FunctionComponent<MainLayoutProps> = (props) => {
  const {
    children,
    title,
    description,
    name,
    type,
    secondaryNavEnabled,
    useEmployerNav,
    isEmployerPage,
  } = props;
  const dispatch = useAppDispatch();
  const { isDesktop, isMobile, isTablet } = useWindowDimensions();
  const isMobileDevice = isMobile || isTablet;
  const userStatus = useAppSelector((state: RootState) => state.userStatus.data);

  const MobileMenu = useEmployerNav ? MobileFooterEmployer : MobileFooter;
  const [shortStarModalIsOpen, setShortStarModalIsOpen] = useState<boolean>(false);
  const urlParams = new URLSearchParams(window.location.search);
  const checkInParam = urlParams.get('check-in');
  const navigatedBy = urlParams.get('navigatedBy');
  const isMobileApp = useGoNative();
  const history = useHistory();

  useEffect(() => {
    if (
      checkInParam !== 'false' &&
      userStatus?.isJoined &&
      userStatus?.isRegistered &&
      userStatus?.isAgreed &&
      checkInParam !== 'false'
    ) {
      getScores();
    }
  }, [userStatus]);

  useEffect(() => {
    if (navigatedBy && navigatedBy === 'alertEmail') {
      logAlertEmail();
    }
  }, [navigatedBy]);

  const logAlertEmail = async () => {
    const ipAddress = await getIpAddress();
    const isNative = isMobileApp;
    const os = navigator.userAgentData?.platform || '';

    const res = await logActionAlertEmail(
      LogTypes.ACTION_ALERT_EMAIL_CLICKED,
      ipAddress,
      os,
      isNative,
    );
    if (res) {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);

      params.delete('navigatedBy');
      const newUrl = `${url.pathname}?${params.toString()}`;
      history.replace(newUrl);
    }
  };

  const getScores = async () => {
    const res = await getShortStarAssessmentScores();
    const lastAssessment = res[res.length - 1];

    if (res.length > 0) {
      dispatch(configurationSliceActions.addLatestAssessmentScore(lastAssessment));
    }

    const today = new Date().toISOString().split('T')[0];

    let localStorageData: LocalStorageData = {};
    const storedData = localStorage.getItem('shortStarCacheHistory');

    if (storedData) {
      localStorageData = JSON.parse(storedData) as LocalStorageData;
    }

    const lastAssessmentDate = lastAssessment
      ? new Date(lastAssessment.createdAt).toISOString().split('T')[0]
      : null;

    if (localStorageData && localStorageData.lastPopupDate !== today) {
      localStorageData.popupClosedToday = false;
    }

    if (lastAssessmentDate === null && !localStorageData.popupClosedToday) {
      await setShortStarModalIsOpen(true);
      await dispatch(configurationSliceActions.forceShortStarModal(true));
      localStorageData.lastPopupDate = today;
    } else if (
      lastAssessmentDate !== today &&
      lastAssessmentDate !== null &&
      !localStorageData.popupClosedToday
    ) {
      await setShortStarModalIsOpen(true);
      localStorageData.lastPopupDate = today;
    } else if (!localStorageData.lastPopupDate || localStorageData.lastPopupDate !== today) {
      if (!localStorageData.popupClosedToday) {
        await setShortStarModalIsOpen(true);
        localStorageData.lastPopupDate = today;
      }
    }

    localStorage.setItem('shortStarCacheHistory', JSON.stringify(localStorageData));
  };

  return (
    <ThemeContextProvider>
      <ProSidebarProvider>
        <Seo title={title} description={description} name={name} type={type} />
        <ToastContainer hideProgressBar theme="dark" />
        <div className={styles.mainLayout}>
          <div className="sticky-top">
            {isDesktop && <Header useEmployerNav={useEmployerNav} />}
            {!secondaryNavEnabled && <SecondaryNavigation />}
          </div>
          <div className={isEmployerPage ? undefined : 'boxed'}>{children}</div>
          <Sidenav />
          {isDesktop && <Footer />}
          {isMobileDevice && <MobileMenu />}

          {shortStarModalIsOpen &&
            ReactDOM.createPortal(
              <ShortStarModal
                modalOpen={shortStarModalIsOpen}
                onClose={() => setShortStarModalIsOpen(false)}
              />,
              document.getElementById('short-star-modal')!,
            )}
        </div>
      </ProSidebarProvider>
    </ThemeContextProvider>
  );
};

MainLayout.defaultProps = {
  description: '',
  name: '',
  type: 'summary',
  secondaryNavEnabled: false,
  useEmployerNav: false,
  isEmployerPage: false,
};

interface LocalStorageData {
  lastPopupDate?: string;
  popupClosedToday?: boolean;
}

interface MainLayoutProps {
  children: React.ReactNode | undefined;
  title: string;
  description?: string;
  name?: string;
  type?: TwitterCardType;
  secondaryNavEnabled?: boolean;
  useEmployerNav?: boolean;
  isEmployerPage?: boolean;
}

export default MainLayout;
