import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import FormButtonNew from '@components/FormButtonNew/FormButtonNew';
import LabelInput from '@components/Molecules/LabelInput';
import {
  JobTitleType,
  JobTypeType,
  LocationItemType,
  SeniorityType,
  SelectableOptionType,
  TeamItemType,
  updateUserStatusRequest,
} from 'custom';
import useInput, { getInputValue, getSelectValue } from '@hooks/useInput';
import LabelSelect from '@components/Molecules/LabelSelect';
import YearMonthSelect from '@components/Molecules/YearMonthSelect';

import { getTeams } from '@api/teams';
import { getLocations } from '@api/locations';
import { getJobTitles } from '@api/jobTitles';
import { getJobTypes } from '@api/jobTypes';
import { getSeniority } from '@api/seniority';
import { completeRegisterUser } from '@api/users';
import useIsFormValid from '@hooks/useIsFormValid';
import { isEmptyValidation, isNullValidation, noValidation } from '@helpers/validation.helper';
import { toast } from 'react-toastify';
import { getCompanySignUpFields } from '@api/auth/company';
import { updateUserStatusData } from 'store/Actions/UserStatusAction';
import { useAppDispatch } from '@hooks/useAppDispatch';
import styles from './index.module.scss';

const CompleteRegistrationForm: React.FunctionComponent<CompleteRegistrationFormProps> = (
  props,
) => {
  const { user } = props;
  const [fields, setFields] = useState<Array<{ field_id: string }>>([]);
  const [teams, setTeams] = useState<Array<SelectableOptionType>>([]);
  const [locations, setLocations] = useState<Array<SelectableOptionType>>([]);
  const [jobTitles, setJobTitles] = useState<Array<SelectableOptionType>>([]);
  const [jobTypes, setJobTypes] = useState<Array<SelectableOptionType>>([]);
  const [seniorityList, setSeniorityList] = useState<Array<SelectableOptionType>>([]);
  const startWorkingYears = 18;
  const endWorkingYears = 80;
  const endYear = new Date().getFullYear() - startWorkingYears;
  const startYear = endYear - endWorkingYears;
  const history = useHistory();
  const dispatch = useAppDispatch();
  let isFormValid = false;

  const enum FieldList {
    UNIQUE_ID = 'uniqueId',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    JOB_LOCATION = 'jobLocation',
    TEAM = 'team',
    JOB_TITLE = 'jobTitle',
    JOB_TYPE = 'jobType',
    SENIORITY = 'seniority',
    REPORTING = 'reporting',
    GENDER = 'gender',
    BIRTH_YEAR_MONTH = 'birthYearMonth',
  }

  useEffect(() => {
    const getFields = async () => {
      const fieldsList = await getCompanySignUpFields();
      if (fieldsList.length === 0) {
        history.push('/introduction');
      }

      setFields(fieldsList);
    };

    getFields();
  }, [history]);

  const genderOptions: SelectableOptionType[] = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Non-binary', value: 'non_binary' },
  ];

  const reportingOptions: SelectableOptionType[] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  const getTeamData = async () => {
    const teamsResponse = await getTeams();
    const teamsOptions = teamsResponse
      ? teamsResponse.map((team: TeamItemType) => ({
          label: team.name,
          value: team.id,
        }))
      : [];
    setTeams(teamsOptions);
  };

  const getLocationData = async () => {
    const locationsResponse = await getLocations();
    const locationOptions = locationsResponse
      ? locationsResponse.map((location: LocationItemType) => ({
          label: location.name,
          value: location.id,
        }))
      : [];
    setLocations(locationOptions);
  };

  const getJobTitleData = async () => {
    const jobTitlesResponse = await getJobTitles();
    const jobTitleOption = jobTitlesResponse
      ? jobTitlesResponse.map((jobTitle: JobTitleType) => ({
          label: jobTitle.name,
          value: jobTitle.id,
        }))
      : [];
    setJobTitles(jobTitleOption);
  };

  const getJobTypeData = async () => {
    const jobTypesResponse = await getJobTypes();
    const jobTypesOptions = jobTypesResponse
      ? jobTypesResponse.map((jobType: JobTypeType) => ({
          label: jobType.name,
          value: jobType.id,
        }))
      : [];
    setJobTypes(jobTypesOptions);
  };

  const getSeniorityData = async () => {
    const seniorityResponse = await getSeniority();
    const seniorityOptions = seniorityResponse
      ? seniorityResponse.map((seniorityType: SeniorityType) => ({
          label: seniorityType.name,
          value: seniorityType.id,
        }))
      : [];
    setSeniorityList(seniorityOptions);
  };

  useEffect(() => {
    if (fields.length > 0) {
      fields.forEach((_field) => {
        switch (_field.field_id) {
          case FieldList.JOB_LOCATION:
            getLocationData();
            break;

          case FieldList.TEAM:
            getTeamData();
            break;

          case FieldList.JOB_TITLE:
            getJobTitleData();
            break;

          case FieldList.JOB_TYPE:
            getJobTypeData();
            break;

          case FieldList.SENIORITY:
            getSeniorityData();
            break;

          default:
            break;
        }
      });
    }
  }, [
    fields,
    FieldList.JOB_LOCATION,
    FieldList.JOB_TITLE,
    FieldList.JOB_TYPE,
    FieldList.SENIORITY,
    FieldList.TEAM,
  ]);

  const {
    value: uniqueId,
    isValid: uniqueIdIsValid,
    hasError: uniqueIdHasError,
    valueChangeHandler: uniqueIdChangeHandler,
    inputBlurHandler: uniqueIdBlurHandler,
    reset: uniqueIdNameReset,
  } = useInput(isEmptyValidation, getInputValue, '');

  const {
    value: firstName,
    isValid: firstNameIsValid,
    hasError: firstNameHasError,
    valueChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    reset: firstNameReset,
  } = useInput(isEmptyValidation, getInputValue, '');

  const {
    value: lastName,
    isValid: lastNameIsValid,
    hasError: lastNameHasError,
    valueChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
    reset: lastNameReset,
  } = useInput(isEmptyValidation, getInputValue, '');

  const {
    value: jobLocation,
    isValid: jobLocationIsValid,
    hasError: jobLocationHasError,
    valueChangeHandler: jobLocationChangeHandler,
    inputBlurHandler: jobLocationBlurHandler,
    reset: jobLocationReset,
  } = useInput(isNullValidation, getSelectValue, null);

  const {
    value: team,
    isValid: teamIsValid,
    hasError: teamHasError,
    valueChangeHandler: teamChangeHandler,
    inputBlurHandler: teamBlurHandler,
    reset: teamReset,
  } = useInput(isNullValidation, getSelectValue, null);

  const {
    value: jobTitle,
    isValid: jobTitleIsValid,
    hasError: jobTitleHasError,
    valueChangeHandler: jobTitleChangeHandler,
    inputBlurHandler: jobTitleBlurHandler,
    reset: jobTitleReset,
  } = useInput(isNullValidation, getSelectValue, null);

  const {
    value: jobType,
    isValid: jobTypeIsValid,
    hasError: jobTypeHasError,
    valueChangeHandler: jobTypeChangeHandler,
    inputBlurHandler: jobTypeBlurHandler,
    reset: jobTypeReset,
  } = useInput(isNullValidation, getSelectValue, null);

  const {
    value: seniority,
    isValid: seniorityIsValid,
    hasError: seniorityHasError,
    valueChangeHandler: seniorityChangeHandler,
    inputBlurHandler: seniorityBlurHandler,
    reset: seniorityReset,
  } = useInput(isNullValidation, getSelectValue, null);

  const {
    value: reporting,
    isValid: reportingIsValid,
    hasError: reportingHasError,
    valueChangeHandler: reportingChangeHandler,
    inputBlurHandler: reportingBlurHandler,
    reset: reportingReset,
  } = useInput(isNullValidation, getSelectValue, reportingOptions[1]);

  const {
    value: gender,
    isValid: genderIsValid,
    hasError: genderHasError,
    valueChangeHandler: genderChangeHandler,
    inputBlurHandler: genderBlurHandler,
    reset: genderReset,
  } = useInput(isNullValidation, getSelectValue, null);

  const {
    value: month,
    isValid: monthIsValid,
    hasError: monthHasError,
    valueChangeHandler: monthChangeHandler,
    inputBlurHandler: monthBlurHandler,
    reset: monthReset,
  } = useInput(noValidation, getSelectValue, null);

  const {
    value: year,
    isValid: yearIsValid,
    hasError: yearHasError,
    valueChangeHandler: yearChangeHandler,
    inputBlurHandler: yearBlurHandler,
    reset: yearReset,
  } = useInput(noValidation, getSelectValue, null);

  let validatingFieldList: boolean[] = [];
  if (fields.length > 0) {
    validatingFieldList = fields.map((_field) => {
      switch (_field.field_id) {
        case FieldList.UNIQUE_ID:
          return uniqueIdIsValid;
          break;

        case FieldList.FIRST_NAME:
          return firstNameIsValid;
          break;

        case FieldList.LAST_NAME:
          return lastNameIsValid;
          break;

        case FieldList.JOB_LOCATION:
          return jobLocationIsValid;
          break;

        case FieldList.TEAM:
          return teamIsValid;
          break;

        case FieldList.JOB_TITLE:
          return jobTitleIsValid;
          break;

        case FieldList.JOB_TYPE:
          return jobTypeIsValid;
          break;

        case FieldList.SENIORITY:
          return seniorityIsValid;
          break;

        case FieldList.REPORTING:
          return reportingIsValid;
          break;

        case FieldList.GENDER:
          return genderIsValid;
          break;

        default:
          return true;
          break;
      }
    });
  }

  isFormValid = useIsFormValid(...validatingFieldList);

  const handleCreateUser = async (e: any) => {
    e.preventDefault();
    let response: any;

    try {
      if (isFormValid) {
        const payload = {
          uniqueId,
          firstName,
          lastName,
          jobLocation: jobLocation ? jobLocation.value : null,
          team: team ? team.value : null,
          jobTitle: jobTitle ? jobTitle.value : null,
          jobType: jobType ? jobType.value : null,
          seniority: seniority ? seniority.value : null,
          reporting: reporting ? reporting.value : null,
          gender: gender ? gender.value : null,
          birthMonth: month ? month.value : null,
          birthYear: year ? year.value : null,
        };
        response = await completeRegisterUser(user.id, payload);
      }

      if (response.data) {
        const data: updateUserStatusRequest = {
          is_registered: true,
        };
        dispatch(updateUserStatusData(data));
        await toast.success(response.data || 'Updated data successfully', {
          autoClose: 250,
          onClose: async () => {
            await history.push('/introduction');
          },
        });
      } else {
        toast.error(response.message || 'Error completing the registration please try again');
      }
    } catch (err: any) {
      toast.error(err.message || 'Error completing the registration please try again');
    }
  };

  let uiFields;
  if (fields.length > 0) {
    uiFields = fields.map((_field) => {
      switch (_field.field_id) {
        case FieldList.UNIQUE_ID:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelInput
                id="uniqueId"
                label="User Id *"
                type="text"
                name="uniqueId"
                placeholder="Enter your user id provided by your organisation"
                onChange={uniqueIdChangeHandler}
                onBlur={uniqueIdBlurHandler}
                value={uniqueId || ''}
                isError={uniqueIdHasError}
              />
              {uniqueIdHasError && (
                <p className={styles.hsError}>
                  Please enter your user id provided by your organisation. If you are unsure about
                  your user id, please contact your administrator for assistance.
                </p>
              )}
            </div>
          );
          break;

        case FieldList.FIRST_NAME:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelInput
                id="firstName"
                label="First Name *"
                type="text"
                name="firstName"
                placeholder="Enter your first name"
                onChange={firstNameChangeHandler}
                onBlur={firstNameBlurHandler}
                value={firstName || ''}
                isError={firstNameHasError}
              />
              {firstNameHasError && <p className={styles.hsError}>Please enter your first name</p>}
            </div>
          );
          break;

        case FieldList.LAST_NAME:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelInput
                id="lastName"
                label="Last Name *"
                type="text"
                name="lastName"
                placeholder="Enter your last name"
                onChange={lastNameChangeHandler}
                onBlur={lastNameBlurHandler}
                value={lastName || ''}
                isError={lastNameHasError}
              />
              {lastNameHasError && <p className={styles.hsError}>Please enter your last name</p>}
            </div>
          );
          break;

        case FieldList.JOB_LOCATION:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelSelect
                id="jobLocation"
                name="jobLocation"
                label="Job location *"
                options={locations}
                value={jobLocation || null}
                placeholder="Select your job location"
                onChange={jobLocationChangeHandler}
                onBlur={jobLocationBlurHandler}
                isError={jobLocationHasError}
              />
              {jobLocationHasError && (
                <p className={styles.hsError}>Please select your job location</p>
              )}
            </div>
          );
          break;

        case FieldList.JOB_TYPE:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelSelect
                id="jobType"
                name="jobType"
                label="Type *"
                options={jobTypes}
                placeholder="Select type"
                onChange={jobTypeChangeHandler}
                onBlur={jobTypeBlurHandler}
                value={jobType || null}
                isError={jobTypeHasError}
              />
              {jobTypeHasError && <p className={styles.hsError}>Please select type</p>}
            </div>
          );
          break;

        case FieldList.TEAM:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelSelect
                id="team"
                name="team"
                label="Team *"
                options={teams}
                value={team || null}
                placeholder="Select team"
                onChange={teamChangeHandler}
                onBlur={teamBlurHandler}
                isError={teamHasError}
              />
              {teamHasError && <p className={styles.hsError}>Please select a team</p>}
            </div>
          );
          break;

        case FieldList.JOB_TITLE:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelSelect
                id="jobTitle"
                name="jobTitle"
                label="Job title *"
                options={jobTitles}
                value={jobTitle || null}
                placeholder="Select your job title"
                onChange={jobTitleChangeHandler}
                onBlur={jobTitleBlurHandler}
                isError={jobTitleHasError}
              />
              {jobTitleHasError && <p className={styles.hsError}>Please select your job title</p>}
            </div>
          );
          break;

        case FieldList.SENIORITY:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelSelect
                id="seniority"
                name="seniority"
                label="Seniority *"
                options={seniorityList}
                placeholder="Select your seniority"
                value={seniority || null}
                onChange={seniorityChangeHandler}
                onBlur={seniorityBlurHandler}
                isError={seniorityHasError}
              />
              {seniorityHasError && <p className={styles.hsError}>Please select your seniority</p>}
            </div>
          );
          break;

        case FieldList.REPORTING:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelSelect
                id="reporting"
                name="reporting"
                label="Anyone reporting to you? *"
                options={reportingOptions}
                value={reporting || reportingOptions[1]}
                onChange={reportingChangeHandler}
                onBlur={reportingBlurHandler}
                isError={reportingHasError}
                isClearable={false}
              />
            </div>
          );
          break;

        case FieldList.GENDER:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <LabelSelect
                id="gender"
                name="gender"
                label="Gender *"
                options={genderOptions}
                value={gender || null}
                placeholder="Select your gender"
                onChange={genderChangeHandler}
                onBlur={genderBlurHandler}
                isError={genderHasError}
              />
              {genderHasError && <p className={styles.hsError}>Please select your gender</p>}
            </div>
          );
          break;

        case FieldList.BIRTH_YEAR_MONTH:
          return (
            <div key={_field.field_id} className={styles.inputHolder}>
              <YearMonthSelect
                id="birthYearMonth"
                name="birthYearMonth"
                label="Month and year of birth"
                startYear={startYear}
                endYear={endYear}
                onMonthChange={monthChangeHandler}
                onYearChange={yearChangeHandler}
                onMonthBlur={monthBlurHandler}
                onYearBlur={yearBlurHandler}
              />
            </div>
          );
          break;

        default:
          return <></>;
          break;
      }
    });
  }

  return (
    <form onSubmit={handleCreateUser}>
      {uiFields}
      <div className={styles.buttonHolder}>
        <FormButtonNew
          onClick={handleCreateUser}
          disabled={!isFormValid}
          color="primary"
          type="submit"
        >
          Enter Hapstar
        </FormButtonNew>
      </div>
    </form>
  );
};

CompleteRegistrationForm.displayName = 'CompleteRegistrationForm';

interface CompleteRegistrationFormProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  user: any;
}

export default CompleteRegistrationForm;
