import Img from '@components/Atoms/Img';
import { SET_CURRENT_TAB, useGlobalState } from '@context/GlobalStore';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import { ThemeContext } from '@context/ThemeContext';
import { getCompanyDetails } from '@helpers/auth.helper';
import { useAppSelector } from '@hooks/useAppSelector';
import classNames from 'classnames';
import { CompanyDetails, UserRoles } from 'custom.d';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { RootState } from 'store';
import logoLight from '../../../assets/images/logo-light.svg';
import logo from '../../../assets/images/logo.svg';
import employerMenuItems from '../../../config/employer-main-menu.config';
import menuItems from '../../../config/main-menu.config';
import Hamburger from './components/Hamburger';
import styles from './index.module.scss';

const Header: React.FunctionComponent<HeaderProps> = (props) => {
  const user = localStorage.getItem('user');
  const parsedUser = JSON.parse(user || '{}');
  const { useEmployerNav } = props;
  const { isDark } = useContext(ThemeContext);
  const { toggleSubNav } = useContext(SecondaryNavigationContext);
  const history = useHistory();
  const [state, dispatch] = useGlobalState();
  const notifications = false;
  const location = useLocation();
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>();
  const companyConfigData = useAppSelector((st: RootState) => st.companyConfig);
  let organizationLogo;
  let organizationAltText = 'organization logo';

  const filteredMenuItems = menuItems.filter((item) => {
    if (!companyConfigData.data.showInstantHelp && item.name === 'Hub') {
      return false;
    }

    if (!companyConfigData.data.expertsEnabled && item.name === 'Experts') {
      return false;
    }

    return true;
  });

  const filteredEmployerMenuItems =
    parsedUser && parsedUser.role !== UserRoles.SUPER_ADMIN
      ? employerMenuItems.filter((item) => item.name !== 'Learnings')
      : employerMenuItems;
  const selectedMenuItems = useEmployerNav ? filteredEmployerMenuItems : filteredMenuItems;
  const defaultSubNav = { id: 1, name: 'Overall' };

  useEffect(() => {
    const getCompanyData = async () => {
      const data = await getCompanyDetails();
      if (data) {
        setCompanyDetails(data);
      }
    };

    getCompanyData();
  }, []);

  if (companyDetails) {
    organizationLogo = companyDetails.company_logo
      ? `${process.env.PUBLIC_URL}/images/logos/${companyDetails.company_logo}`
      : null;
    organizationAltText = companyDetails.company_name && `${companyDetails.company_name} logo`;
  }

  const isEmployerPage = useMemo(
    () => location.pathname.split('/')[1] === 'employer',
    [location.pathname],
  );

  return (
    <div className={`${styles.hsHeader} ${isDark && styles.hsDark}`}>
      <nav className={styles.hsNav}>
        <div className={styles.hsLogoHolder}>
          <div
            onClick={() => {
              toggleSubNav({ id: 1, name: 'Overall' });
              history.push(isEmployerPage ? '/employer/homepage' : '/');
            }}
          >
            <Img
              alt="Hapstar logo"
              src={isDark ? logoLight : logo}
              className={classNames([organizationLogo ? styles.companyLogo : styles.companyFull])}
            />
          </div>
          {organizationLogo && (
            <div>
              <Img alt={organizationAltText} src={organizationLogo} className={styles.img} />
            </div>
          )}
        </div>
        <div className={styles.menuContainer}>
          <ul>
            {selectedMenuItems.map((item, index) => (
              <li key={index}>
                <div
                  onClick={() => {
                    dispatch({ type: SET_CURRENT_TAB, payload: { id: 1, name: 'Overall' } });
                    if (item.name === 'Scores')
                      toggleSubNav(
                        JSON.parse(sessionStorage.getItem('subNav') as any) || defaultSubNav,
                      );
                  }}
                >
                  <NavLink
                    to={item.path}
                    exact={item.exact}
                    activeClassName={styles.active}
                    className={
                      (location.pathname.includes('/post') && item.name === 'Learn') ||
                      (location.pathname.includes('/expert') && item.name === 'Experts')
                        ? styles.active
                        : ''
                    }
                  >
                    {item.name}
                  </NavLink>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.notificationsHamburgerContainer}>
          {notifications && <i className="icon icon-bell" />}
          <Hamburger isDark={isDark} />
        </div>
      </nav>
    </div>
  );
};

Header.defaultProps = {
  useEmployerNav: false,
};

interface HeaderProps {
  useEmployerNav?: boolean;
}

export default Header;
