import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const getFocusAreaData = async (questionnaireId = 1, assesmentId = 0) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/focus-areas/${questionnaireId}/${assesmentId}`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );
  const result: any = await response.json();

  return result;
};

export const GetFocusAreaSuggestions = async (focusAreaId: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/focus-area-suggestions/${focusAreaId}`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );
  const result: any = await response.json();

  return result;
};

export const saveFocusArea = async (data: any, questionnaireId = 1, assesmentId = 0) => {
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}/set-focus-areas/${questionnaireId}/${assesmentId}`,
    {
      method: 'POST',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(data),
    },
  );
  const { status } = result;

  return status === 200;
};

export const editFocusAreas = async (data: any, questionnaireId = 1, assesmentId = 0) => {
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}/set-focus-areas/${questionnaireId}/${assesmentId}`,
    {
      method: 'PUT',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
      body: JSON.stringify(data),
    },
  );
  const { status } = result;

  return status === 200;
};
