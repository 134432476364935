import { getScores } from '@api/assessment';
import Button from '@components/Button';
import PopUpModal from '@components/PopUpModal';
import Star, { WingDetails } from '@components/Star';
import wingDetails from '@config/star.config.json';
import { CreateStarContext } from '@context/CreateStarContext';
import { useGlobalState } from '@context/GlobalStore';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import { parseStarDetails } from '@helpers/parser.helper';
import useWindowDimensions from '@hooks/useWindowDimensions';
import React, { useContext, useEffect, useState } from 'react';
import { useWizard } from 'react-use-wizard';
import styles from './index.module.scss';

const StepWizardStar: React.FunctionComponent<StarWithLabelsInterface> = (props) => {
  const { categoryQuestion } = useContext(CreateStarContext);
  const { isEdit = false } = props;
  const [state] = useGlobalState();
  const { goToStep, activeStep, nextStep } = useWizard();
  const { isDesktop } = useWindowDimensions();
  const [realWingDetails, setRealWingDetails] = useState<WingDetails[]>(wingDetails);
  const [open, setOpen] = useState(true);
  const { activeSubNav } = useContext(SecondaryNavigationContext);
  const [isFirstTime, setIsFirstTime] = useState(false);

  useEffect(() => {
    if (categoryQuestion.length > 0) {
      const updatedWingDetails = categoryQuestion.map((category) => {
        const categoryName = category.name;
        const scores = category.data;
        const { color } = category;

        return parseStarDetails(categoryName, color, scores, !isEdit);
      });
      setRealWingDetails(updatedWingDetails);
    }
  }, [isEdit, categoryQuestion]);

  useEffect(() => {
    const getScoresData = async () => {
      setIsFirstTime(false);

      try {
        const data = await getScores(activeSubNav.id);
      } catch (err: any) {
        if (JSON.parse(err.message).message) {
          setIsFirstTime(true);
        }
      }
    };

    if (activeSubNav.id === JSON.parse(sessionStorage.getItem('subNav') as any)?.id) {
      getScoresData();
    }
  }, [activeSubNav.id]);

  const handleClick = (step: number) => {
    localStorage.setItem('changeRequested', step as any);
    goToStep(step);
  };

  const actionButtonHandler = async () => {
    setOpen(false);
    await nextStep();
  };

  const spanStyle = { fontSize: isDesktop ? '20px' : '14px' };
  const editLabel = isDesktop ? 'Edit Score' : 'Edit';

  const updatedDynamicTitle = activeSubNav.name;

  const onboardPopupTitle =
    activeSubNav.name === 'Overall'
      ? 'Let’s start scoring!'
      : `Welcome to your ${activeSubNav?.name?.toLowerCase()?.replace('star', '')} wellbeing`;

  const onboardPopupFirstDescription =
    activeSubNav.name === 'Overall'
      ? 'Each section is broken down into individual drivers where you’ll score your happiness.'
      : 'Here you can explore your happiness in more depth.';

  const onboardPopupDescription =
    activeSubNav.name === 'Overall' ? (
      'When you’re ready, click ‘let’s begin’'
    ) : (
      <ul>
        <li>Score each driver</li>
        <li>Share your thoughts</li>
        <li>Choose your focus areas</li>
        <li>Set your actions</li>
      </ul>
    );

  return (
    <>
      {categoryQuestion.length > 0 && (
        <div className={styles.hsStarWithLabels} style={{ position: 'relative' }}>
          {activeStep === 22 && !isDesktop && (
            <h1 className={styles.title}>You’ve almost completed your {updatedDynamicTitle}!</h1>
          )}

          {activeStep === 7 && (
            <h1
              style={{
                color: '#505050',
                fontSize: '40px',
                fontWeight: '600',
                marginBottom: '80px',
              }}
            >
              You’ve almost completed your {updatedDynamicTitle}!
            </h1>
          )}
          {isFirstTime && !isEdit && (
            <PopUpModal
              top="80px"
              right="0px"
              open={open}
              setOpen={setOpen}
              firstDescription={onboardPopupFirstDescription}
              description={onboardPopupDescription}
              title={onboardPopupTitle}
              buttonLabel="Let's begin"
              onActionClick={actionButtonHandler}
            />
          )}
          {state?.isUserOnBoarded && isEdit && (
            <PopUpModal
              top="80px"
              right="0px"
              open={open}
              setOpen={setOpen}
              description="Here you can share additional thoughts, reflect on and adjust your scores. If you’re happy click submit to finish your star."
              title="Nearly there!"
            />
          )}
          <div style={{ paddingBottom: '50px' }}>
            <table className="mx-auto">
              <tbody>
                <tr>
                  <td colSpan={3} align="center">
                    <span className="mb-3" style={spanStyle}>
                      {categoryQuestion[0].name}
                      {isEdit && (
                        <Button type="small" isButton onClick={() => handleClick(1)}>
                          {editLabel}
                        </Button>
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td align="left">
                    <span style={spanStyle}>
                      {categoryQuestion[5].name}
                      {isEdit && (
                        <Button type="small" isButton onClick={() => handleClick(6)}>
                          {editLabel}
                        </Button>
                      )}
                    </span>
                  </td>
                  <td rowSpan={2}>
                    <div
                      style={{
                        width: isDesktop ? '319px' : '180px',
                        // marginRight: !isDesktop ? -20 : undefined,
                      }}
                    >
                      <Star wingDetails={realWingDetails} />
                    </div>
                  </td>
                  <td width="200">
                    <span style={spanStyle}>
                      {categoryQuestion[1].name}
                      {isEdit && (
                        <Button type="small" isButton onClick={() => handleClick(2)}>
                          {editLabel}
                        </Button>
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td width="200" align="right">
                    <span style={spanStyle}>
                      {categoryQuestion[4].name}
                      {isEdit && (
                        <Button type="small" isButton onClick={() => handleClick(5)}>
                          {editLabel}
                        </Button>
                      )}
                    </span>
                  </td>
                  <td width="200">
                    <span style={spanStyle}>
                      {categoryQuestion[2].name}
                      {isEdit && (
                        <Button type="small" isButton onClick={() => handleClick(3)}>
                          {editLabel}
                        </Button>
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} align="center">
                    <span className="mt-3" style={spanStyle}>
                      {categoryQuestion[3].name}
                      {isEdit && (
                        <Button type="small" isButton onClick={() => handleClick(4)}>
                          {editLabel}
                        </Button>
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

interface StarWithLabelsInterface {
  isEdit?: boolean;
}

StepWizardStar.defaultProps = {
  isEdit: false,
};

export default StepWizardStar;
