import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { rgba } from 'polished';
import useWindowDimensions from '@hooks/useWindowDimensions';
import styles from './index.module.scss';

const RadarMobile: React.FunctionComponent<RadarProps> = (props) => {
  const { isDesktop, isMobile } = useWindowDimensions();
  const {
    name,
    color,
    lightColor,
    darkColor,
    data = [],
    compareData = [],
    showOnScorePagea,
  } = props;

  const rgbaColor = rgba(color, 0.9);
  const parsedItems = data.map((item) => item + 1);

  const notFill = parsedItems.every((item) => item === 1 && item === parsedItems[0]);
  let fillColors = [rgbaColor, 'transparent', 'transparent'];
  let strokeWidthOfFilledArea = showOnScorePagea ? 3 : 0;
  if (notFill) {
    fillColors = ['transparent', 'transparent', rgbaColor];
    strokeWidthOfFilledArea = 0;
  }

  const modifiedData = compareData.map((i: any) =>
    i?.scoreDifference < 0 ? Math.abs(i?.scoreDifference) + 1 : -Math.abs(i?.scoreDifference) + 1,
  );

  if (modifiedData?.length < 2) {
    modifiedData.push(modifiedData[0]);
    modifiedData.push(modifiedData[0]);
    modifiedData.push(modifiedData[0]);
  }

  const newData = data.map((i: any, key: number) => i + modifiedData[key]);

  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors: ['transparent', 'transparent', '#47DCC5'],
    labels: ['', '', '', ''],
    stroke: {
      width: strokeWidthOfFilledArea,
      lineCap: 'butt',
    },
    fill: {
      colors: fillColors,
    },
    markers: {
      size: 0,
    },
    legend: {
      show: false,
    },
    yaxis: {
      show: false,
      tickAmount: 11,
      max: 11,
    },
    plotOptions: {
      radar: {
        polygons: {
          strokeColors: [
            darkColor,
            lightColor,
            lightColor,
            lightColor,
            lightColor,
            lightColor,
            lightColor,
            lightColor,
            lightColor,
            lightColor,
            lightColor,
          ],
          strokeWidth: ['5', '1'],
          connectorColors: [lightColor, lightColor, lightColor, lightColor, lightColor],
        },
      },
    },
  };
  const series = [
    {
      name: '',
      data: parsedItems,
    },
    {
      name: '',
      data: compareData,
    },
    {
      name: '',
      data: newData,
    },
  ];

  return (
    <div className={styles.hsRadarChart}>
      {name && <p>{name}</p>}
      <div style={{ height: '550px', overflow: 'hidden', width: '370px' }}>
        <Chart
          options={options}
          series={series}
          type="radar"
          height={1200}
          style={{ transform: 'scale(3.3, 3.3)' }}
        />
      </div>
    </div>
  );
};

interface RadarProps {
  name?: string;
  color: string;
  lightColor: string;
  darkColor: string;
  data?: number[];
  compareData?: number[];
  showOnScorePagea?: boolean;
}

RadarMobile.defaultProps = {
  name: '',
  data: [0, 0, 0, 0],
  compareData: [0, 0, 0, 0],
  showOnScorePagea: false,
};

export default RadarMobile;
