import React, { useContext, useEffect, useState } from 'react';
import ActionsPageContent from '@components/ActionsPageContent';
import NewScorePageContent from '@components/NewScorePageContent';
import PopUpModal from '@components/PopUpModal';
import QuestionItemMobile from '@components/QuestionItemMobile';
import ShareThoughts from '@components/ShareThoughts';
import StepWizardFinish from '@components/StepWizardFinish';
import StepWizardFooterMobile from '@components/StepWizardFooterMobile';
import StepWizardHeader from '@components/StepWizardHeader';
import StepWizardStarMobile from '@components/StepWizardStarMobile';
import { CreateStarContext } from '@context/CreateStarContext';
import { useGlobalState } from '@context/GlobalStore';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import { getCompanyDetails } from '@helpers/auth.helper';
import { useAppSelector } from '@hooks/useAppSelector';
import { CreateStarCategoryInterface } from '@interfaces/index';
import HelpAndSpeakup from '@pages/EarlyWarning/HelpAndSpeakup';
import classNames from 'classnames';
import { Sidebar, useProSidebar } from 'react-pro-sidebar';
import { Wizard } from 'react-use-wizard';
import { RootState } from 'store';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { getUserImprovePlansByDeepDive } from '@api/actionPlan';
import { starScoresSliceActions } from 'store/Slices/StarScoreSlice';
import { ImprovePlanFocusAreas } from 'custom';
import NewScorePlanSection from '@components/NewScorePlanSection';
import styles from './index.module.scss';

const QuestionItemContainerMobile: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { categoryQuestion, setCategoryQuestion } = useContext(CreateStarContext);
  const { collapsed } = useProSidebar();
  const [allCategories, setAllCategories] = useState<CreateStarCategoryInterface[] | any>([]);
  const [sidebarScore, setSidebarScore] = useState<number>(0);
  const { activeSubNav } = useContext(SecondaryNavigationContext);
  const [sidebarColor, setSidebarColor] = useState<string>('');
  const [open, setOpen] = useState<boolean>(true);
  const [sidebarQuestion, setSidebarQuestion] = useState<string>('');
  const [sidebarId, setSidebarId] = useState<any>('');
  const [state] = useGlobalState();
  const [currentCompanyName, setCurrentCompanyName] = useState('');
  const helpConfig = useAppSelector((st: RootState) => st.helpConfig.data);
  const { isHelpAvailable } = helpConfig;
  const [assesmentDataForHelp, setAssessmentDataForHelp] = useState<AssessmentData>({
    assessmentId: 0,
    questionnaireId: 0,
  });
  const [previewInstantHelp, setPreviewInstantHelp] = useState<boolean>(false);
  const [skipToAnyStep, setSkipToAnyStep] = useState<number>(0);
  const [customButtonName, setCustomButtonName] = useState<string>('');

  useEffect(() => {
    const getCurrentCompanyName = async () => {
      const comDetails = await getCompanyDetails();
      if (comDetails) {
        setCurrentCompanyName(comDetails.company_name);
      }
    };

    getCurrentCompanyName();
  }, []);

  const sidebarClasses = classNames([
    styles.hsQuestionsContainerSidebar,
    collapsed && styles.Collapsed,
  ]);

  useEffect(() => {
    setAllCategories(categoryQuestion);
  }, [categoryQuestion]);

  useEffect(() => {
    getActiveImprovePlans();
  }, []);

  const getActiveImprovePlans = async () => {
    type StepType =
      | 'NO_ACTIVE_PLANS'
      | 'HAS_PLANS_NO_FOCUS_AREAS'
      | 'HAS_BOTH_PLANS_AND_FOCUS_AREAS';
    const uPlans: any = await getUserImprovePlansByDeepDive(activeSubNav.id, true);

    dispatch(starScoresSliceActions.addDeepDiveQuestionWithPlan(uPlans));

    const modUplan = uPlans[0]?.question?.improvePlans;

    let step: StepType = 'NO_ACTIVE_PLANS';
    let focusAreas: ImprovePlanFocusAreas[] = [];

    if (modUplan) {
      if (modUplan?.length === 0) {
        step = 'NO_ACTIVE_PLANS';
      } else if (
        modUplan?.length > 0 &&
        modUplan[0]?.improvePlansFocusAreas.filter((dt: { active: boolean }) => dt.active)
          ?.length === 0
      ) {
        step = 'HAS_PLANS_NO_FOCUS_AREAS';
      } else if (
        modUplan?.length > 0 &&
        modUplan[0]?.improvePlansFocusAreas.filter((dt: { active: boolean }) => dt.active)?.length >
          0
      ) {
        step = 'HAS_BOTH_PLANS_AND_FOCUS_AREAS';
        focusAreas = modUplan[0]?.improvePlansFocusAreas;
      }
    }

    dispatch(starScoresSliceActions.updateCurrentStep({ step, focusAreas }));
  };

  const handleChange = (score: number, questionId: number) => {
    const newCategories = allCategories.map((category: any) => {
      const currentCategory = { ...category };
      const questions = currentCategory.questions.map((question: any) => {
        const currentQuestion = {
          ...question,
        };
        if (currentQuestion.id === questionId) {
          currentQuestion.score = score;
        }

        return currentQuestion;
      });
      const scores = questions.map((q: any) => q.score);
      currentCategory.questions = questions;

      currentCategory.data = scores.length === 1 ? new Array(4).fill(scores[0]) : scores;

      return currentCategory;
    });

    setAllCategories(newCategories);
    setCategoryQuestion(newCategories);
  };

  const handleSidebarOpen = (score: number, color: string, question: string, id: number) => {
    setSidebarScore(score);
    setSidebarColor(color);
    setSidebarQuestion(question);
    setSidebarId(id);
  };

  const shareThoughts = (data: any) => {
    const { shareWith, thoughtsText } = data;

    const newCategories = allCategories.map((category: any) => {
      const currentCategory = { ...category };
      const questions = currentCategory.questions.map((question: any) => {
        const currentQuestion = {
          ...question,
        };
        if (currentQuestion.id === sidebarId) {
          currentQuestion.shareWith = shareWith;
          currentQuestion.thoughtsText = thoughtsText;
        }

        return currentQuestion;
      });
      currentCategory.questions = questions;

      return currentCategory;
    });

    setAllCategories(newCategories);
    setCategoryQuestion(newCategories);
  };

  if (activeSubNav.id === 1) {
    return (
      <div
        className={styles.hsQuestionsContainer}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Wizard
          header={
            <StepWizardHeader
              categoryNames={allCategories
                .flatMap((category: any) => category.questions)
                .flatMap((k: any) => k.name)}
              handleAssessmentData={setAssessmentDataForHelp}
              customButtonName={customButtonName}
            />
          }
          footer={
            <StepWizardFooterMobile
              handleAssessmentData={setAssessmentDataForHelp}
              assesmentData={assesmentDataForHelp}
              skipToAnyStep={skipToAnyStep}
              handleStarSkip={setSkipToAnyStep}
              customButtonName={customButtonName}
            />
          }
        >
          <StepWizardStarMobile />
          <QuestionItemMobile
            categoryQuestions={allCategories[0]}
            question={allCategories[0]?.questions?.[0]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          <QuestionItemMobile
            categoryQuestions={allCategories[1]}
            question={allCategories[1]?.questions?.[0]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          <QuestionItemMobile
            categoryQuestions={allCategories[1]}
            question={allCategories[1]?.questions?.[1]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          <QuestionItemMobile
            categoryQuestions={allCategories[1]}
            question={allCategories[1]?.questions?.[2]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          <QuestionItemMobile
            categoryQuestions={allCategories[1]}
            question={allCategories[1]?.questions?.[3]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          <QuestionItemMobile
            categoryQuestions={allCategories[2]}
            question={allCategories[2]?.questions?.[0]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          <QuestionItemMobile
            categoryQuestions={allCategories[2]}
            question={allCategories[2]?.questions?.[1]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          <QuestionItemMobile
            categoryQuestions={allCategories[2]}
            question={allCategories[2]?.questions?.[2]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          <QuestionItemMobile
            categoryQuestions={allCategories[2]}
            question={allCategories[2]?.questions?.[3]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          <QuestionItemMobile
            categoryQuestions={allCategories[3]}
            question={allCategories[3]?.questions?.[0]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          <QuestionItemMobile
            categoryQuestions={allCategories[3]}
            question={allCategories[3]?.questions?.[1]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          <QuestionItemMobile
            categoryQuestions={allCategories[3]}
            question={allCategories[3]?.questions?.[2]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          <QuestionItemMobile
            categoryQuestions={allCategories[3]}
            question={allCategories[3]?.questions?.[3]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          <QuestionItemMobile
            categoryQuestions={allCategories[4]}
            question={allCategories[4]?.questions?.[0]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          <QuestionItemMobile
            categoryQuestions={allCategories[4]}
            question={allCategories[4]?.questions?.[1]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          <QuestionItemMobile
            categoryQuestions={allCategories[4]}
            question={allCategories[4]?.questions?.[2]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          <QuestionItemMobile
            categoryQuestions={allCategories[4]}
            question={allCategories[4]?.questions?.[3]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          <QuestionItemMobile
            categoryQuestions={allCategories[5]}
            question={allCategories[5]?.questions?.[0]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          <QuestionItemMobile
            categoryQuestions={allCategories[5]}
            question={allCategories[5]?.questions?.[1]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          <QuestionItemMobile
            categoryQuestions={allCategories[5]}
            question={allCategories[5]?.questions?.[2]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          <QuestionItemMobile
            categoryQuestions={allCategories[5]}
            question={allCategories[5]?.questions?.[3]}
            onChange={handleChange}
            onSidebarOpen={handleSidebarOpen}
          />
          {/* <StepWizardStarMobile isEdit /> */}
          <StepWizardFinish />
          {isHelpAvailable && (
            <HelpAndSpeakup
              assesmentDataForHelp={assesmentDataForHelp}
              handleAssessmentData={setAssessmentDataForHelp}
              previewInstantHelp={previewInstantHelp}
              handleInstantPreview={setPreviewInstantHelp}
              skipStarStep={setSkipToAnyStep}
              handleSkipButton={setCustomButtonName}
            />
          )}
          <NewScorePageContent isEditable data={allCategories} />
          <NewScorePlanSection data={allCategories} isWizard />
        </Wizard>

        {!collapsed && state?.isUserOnBoarded && (
          <PopUpModal
            top="150px"
            right="700px"
            open={open}
            setOpen={setOpen}
            description={`Any thoughts shared with ${currentCompanyName} are anonymous. This is your chance to be open and be heard.`}
            title="Sharing your thoughts"
          />
        )}

        <Sidebar
          className={sidebarClasses}
          defaultCollapsed
          backgroundColor="white"
          collapsedWidth="0px"
          width="390px"
        >
          {!collapsed && (
            <ShareThoughts
              id={sidebarId}
              score={sidebarScore}
              color={sidebarColor}
              question={sidebarQuestion}
              shareThoughts={(data) => shareThoughts(data)}
            />
          )}
        </Sidebar>
        <div className={styles.hsQuestionsContainerSidebarOverlay} />
      </div>
    );
  }

  return (
    <div
      className={styles.hsQuestionsContainer}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Wizard
        header={
          <StepWizardHeader
            categoryNames={allCategories
              .flatMap((category: any) => category.questions)
              .flatMap((k: any) => k.name)}
            handleAssessmentData={setAssessmentDataForHelp}
            customButtonName={customButtonName}
          />
        }
        footer={
          <StepWizardFooterMobile
            handleAssessmentData={setAssessmentDataForHelp}
            assesmentData={assesmentDataForHelp}
            skipToAnyStep={skipToAnyStep}
            handleStarSkip={setSkipToAnyStep}
            customButtonName={customButtonName}
          />
        }
      >
        <StepWizardStarMobile />
        <QuestionItemMobile
          categoryQuestions={allCategories[0]}
          question={allCategories[0]?.questions?.[0]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[0]}
          question={allCategories[0]?.questions?.[1]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[0]}
          question={allCategories[0]?.questions?.[2]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[0]}
          question={allCategories[0]?.questions?.[3]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[1]}
          question={allCategories[1]?.questions?.[0]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[1]}
          question={allCategories[1]?.questions?.[1]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[1]}
          question={allCategories[1]?.questions?.[2]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[1]}
          question={allCategories[1]?.questions?.[3]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[2]}
          question={allCategories[2]?.questions?.[0]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[2]}
          question={allCategories[2]?.questions?.[1]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[2]}
          question={allCategories[2]?.questions?.[2]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[2]}
          question={allCategories[2]?.questions?.[3]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[3]}
          question={allCategories[3]?.questions?.[0]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[3]}
          question={allCategories[3]?.questions?.[1]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[3]}
          question={allCategories[3]?.questions?.[2]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[3]}
          question={allCategories[3]?.questions?.[3]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[4]}
          question={allCategories[4]?.questions?.[0]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[4]}
          question={allCategories[4]?.questions?.[1]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[4]}
          question={allCategories[4]?.questions?.[2]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[4]}
          question={allCategories[4]?.questions?.[3]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[5]}
          question={allCategories[5]?.questions?.[0]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[5]}
          question={allCategories[5]?.questions?.[1]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[5]}
          question={allCategories[5]?.questions?.[2]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        <QuestionItemMobile
          categoryQuestions={allCategories[5]}
          question={allCategories[5]?.questions?.[3]}
          onChange={handleChange}
          onSidebarOpen={handleSidebarOpen}
        />
        {/* <StepWizardStarMobile isEdit /> */}
        <StepWizardFinish />
        {isHelpAvailable && (
          <HelpAndSpeakup
            assesmentDataForHelp={assesmentDataForHelp}
            handleAssessmentData={setAssessmentDataForHelp}
            previewInstantHelp={previewInstantHelp}
            handleInstantPreview={setPreviewInstantHelp}
            skipStarStep={setSkipToAnyStep}
            handleSkipButton={setCustomButtonName}
          />
        )}
        <NewScorePageContent isEditable data={allCategories} />
        <NewScorePlanSection data={allCategories} isWizard />
      </Wizard>

      {!collapsed && state?.isUserOnBoarded && (
        <PopUpModal
          top="150px"
          right="700px"
          open={open}
          setOpen={setOpen}
          description="Any thoughts shared with your organisation are anonymous. This is your chance to be open and be heard."
          title="Sharing your thoughts"
        />
      )}

      <Sidebar
        className={sidebarClasses}
        defaultCollapsed
        backgroundColor="white"
        collapsedWidth="0px"
        width="390px"
      >
        {!collapsed && (
          <ShareThoughts
            id={sidebarId}
            score={sidebarScore}
            color={sidebarColor}
            question={sidebarQuestion}
            shareThoughts={(data) => shareThoughts(data)}
          />
        )}
      </Sidebar>
      <div className={styles.hsQuestionsContainerSidebarOverlay} />
    </div>
  );
};

interface AssessmentData {
  assessmentId: number;
  questionnaireId: number;
}

export default QuestionItemContainerMobile;
