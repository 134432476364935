import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

const Img: React.FunctionComponent<ImgProps> = (props) => {
  const { alt, src, className, ...rest } = props;

  return <img alt={alt} src={src} className={classNames([className])} {...rest} />;
};

Img.defaultProps = {
  className: styles.image,
};

interface ImgProps {
  alt: string;
  src: string;
  className?: string;
}

export default Img;
