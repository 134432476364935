import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const getResponsesSummarys = async () => {
  const result = await fetch(`${process.env.REACT_APP_API_URL}/responses-summary`, {
    method: 'GET',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });

  const result1: any = await result.json();

  return result1;
};

export const updateResponse = async (thoughtId = 0) => {
  await fetch(`${process.env.REACT_APP_API_URL}/responses/${thoughtId}`, {
    method: 'POST',
    headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
  });
};

export const getStarsResponses = async (questionnaireId = 1, assesmentId = 0) => {
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}/responses/${questionnaireId}/${assesmentId}`,
    {
      method: 'GET',
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  const result1: any = await result.json();

  return result1;
};
