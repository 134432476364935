import { createImprovePlan, updateActionsByImprovePlanId } from '@api/actionPlan';
import { saveAssessment } from '@api/assessment';
import { getIpAddress } from '@api/auth/login';
import { saveFocusArea } from '@api/focusArea';
import { getInstantHelpLimits, logInstantHelpStarDetails } from '@api/instantHelp';
import backIcon from '@assets/images/icons/chevron-left.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import { CreateStarContext } from '@context/CreateStarContext';
import { SET_WIZZARD_STEP, useGlobalState } from '@context/GlobalStore';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import { smoothScrollTo } from '@helpers/general.helpers';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import useGoNative from '@hooks/useGoNative';
import { CreateStarCategoryInterface } from '@interfaces/index';
import { HelpLimitData, ImprovePlanTypes, LogTypes } from 'custom.d';
import React, { useContext, useEffect, useState } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { useHistory } from 'react-router-dom';
import { useWizard } from 'react-use-wizard';
import { RootState } from 'store';
import { updateHelpAvailability } from 'store/Actions/HelpAndSpeakupAction';
import { starScoresSliceActions } from 'store/Slices/StarScoreSlice';
import styles from './index.module.scss';

const StepWizardFooterMobile: React.FunctionComponent<StepWizardFooterMobileInterface> = ({
  handleAssessmentData,
  assesmentData,
  skipToAnyStep,
  handleStarSkip,
  customButtonName,
}) => {
  const isMobileApp = useGoNative();
  const history = useHistory();
  const reduxDispatch = useAppDispatch();
  const { categoryQuestion, setCategoryQuestion } = useContext(CreateStarContext);
  const [state, dispatch] = useGlobalState();
  const { activeSubNav } = useContext(SecondaryNavigationContext);
  const { previousStep, nextStep, isFirstStep, isLastStep, goToStep, activeStep } = useWizard();
  const [data, setData] = useState<CreateStarCategoryInterface[]>([]);
  const [selectedCount, setSelectedCount] = useState<number>(0);
  const [idsQuestionaires, setIdsquestionaires] = useState<any>([]);
  const [questions, setQuestions] = useState<any>([]);
  const helpConfig = useAppSelector((st: RootState) => st.helpConfig.data);
  const { isHelpAvailable } = helpConfig;
  const companyConfig = useAppSelector((st: RootState) => st.companyConfig.data);
  const { showSpeakUp } = companyConfig;
  const [questionLimits, setQuestionLimits] = useState<HelpLimitData[]>([]);
  const [failsQuestions, setFailsQuestions] = useState<FailsQuestionsInterface[]>([]);
  const reduxStarScores = useAppSelector((st: RootState) => st.starScores);

  const { collapseSidebar } = useProSidebar();

  useEffect(() => {
    getInstantHelpLimits().then((response) => {
      setQuestionLimits(response);
    });
  }, []);

  useEffect(() => {
    if (skipToAnyStep > 0) {
      goToStep(skipToAnyStep);
      handleStarSkip(0);
    }
  }, [skipToAnyStep]);

  useEffect(() => {
    if (activeSubNav.id === 1) {
      if (isHelpAvailable && assesmentData.assessmentId > 0 && activeStep === 23) {
        logStarInstantHelp();
      }
    } else if (activeSubNav.id !== 1) {
      if (isHelpAvailable && assesmentData.assessmentId > 0 && activeStep === 26) {
        logStarInstantHelp();
      }
    }
  }, [assesmentData, activeStep, isHelpAvailable, activeSubNav.id]);

  useEffect(() => {
    setData(categoryQuestion);
    let selectedFocusAreas = 0;
    categoryQuestion.forEach((category) => {
      selectedFocusAreas += category.questions.filter((qs) => qs.isSelected).length;
    });
    setSelectedCount(selectedFocusAreas);
  }, [categoryQuestion]);

  useEffect(() => {
    const ids = categoryQuestion.flatMap((category) =>
      category.questions.filter((i) => i.isSelected).flatMap((o) => o.id),
    );
    setIdsquestionaires(ids);
    setQuestions(categoryQuestion);
  }, [categoryQuestion]);

  const changeRequested = localStorage.getItem('changeRequested') as any;

  function checkScoreFailsLimit() {
    const resultedQues: { question_id: number; limit: number }[] = [];

    questionLimits.forEach((limitItem) => {
      const questionItem = data.find((item) =>
        item.questions.some((q) => q.id === limitItem.question_id),
      );

      if (questionItem) {
        questionItem.questions.forEach((question) => {
          if (question.id === limitItem.question_id) {
            if (question.score <= limitItem.limit && question.score !== 0) {
              resultedQues.push({
                question_id: question.id,
                limit: limitItem.limit,
              });
            }
          }
        });
      }
    });

    const checkValidity = resultedQues?.length > 0;
    setFailsQuestions(resultedQues);
    reduxDispatch(updateHelpAvailability(checkValidity));
  }

  const logStarInstantHelp = async () => {
    const updatedQuestions = failsQuestions.map((failQue) => ({
      ...failQue,
      assessment_id: assesmentData.assessmentId,
    }));

    const payload = {
      logType:
        isHelpAvailable && showSpeakUp
          ? LogTypes.SPEAKUP_DISPLAYED
          : LogTypes.INSTANT_HELP_DISPLAYED,
      ipAddress: await getIpAddress(),
      isNative: isMobileApp,
      os: navigator.userAgentData?.platform || '',
    };

    if (isHelpAvailable && showSpeakUp) {
      await logInstantHelpStarDetails(
        LogTypes.SPEAKUP_DISPLAYED,
        payload.ipAddress,
        payload.os,
        payload.isNative,
      );

      await logInstantHelpStarDetails(
        LogTypes.INSTANT_HELP_DISPLAYED,
        payload.ipAddress,
        payload.os,
        payload.isNative,
        updatedQuestions,
      );
    } else {
      await logInstantHelpStarDetails(
        payload.logType,
        payload.ipAddress,
        payload.os,
        payload.isNative,
        updatedQuestions,
      );
    }
  };

  const handleNext = async () => {
    checkScoreFailsLimit();
    collapseSidebar(true);

    if (!isLastStep && activeSubNav.id === 1) {
      if (changeRequested) {
        await nextStep();

        if (parseInt(changeRequested, 10) === 1 && activeStep === 1) {
          const response = await saveAssessment(data, activeSubNav.id);
          if (isHelpAvailable && response.data) {
            await handleAssessmentData({
              assessmentId: response?.data?.assessment_id,
              questionnaireId: response?.data?.questionnaire_id,
            });
          }

          localStorage.removeItem('changeRequested');
          goToStep(23);
        }

        if (parseInt(changeRequested, 10) + 3 === activeStep) {
          const response = await saveAssessment(data, activeSubNav.id);
          if (isHelpAvailable && response.data) {
            await handleAssessmentData({
              assessmentId: response?.data?.assessment_id,
              questionnaireId: response?.data?.questionnaire_id,
            });
          }

          localStorage.removeItem('changeRequested');
          goToStep(23);
        }
      } else {
        if (activeStep === 21) {
          const response = await saveAssessment(data, activeSubNav.id);
          if (isHelpAvailable && response.data) {
            await handleAssessmentData({
              assessmentId: response?.data?.assessment_id,
              questionnaireId: response?.data?.questionnaire_id,
            });
          }
        }

        if (
          (isHelpAvailable ? activeStep === 24 : activeStep === 23) &&
          idsQuestionaires.length > 0
        ) {
          await saveFocusArea(idsQuestionaires, activeSubNav.id);
        }

        await nextStep();
        dispatch({ type: SET_WIZZARD_STEP, payload: activeStep });
      }

      smoothScrollTo(0, 100);
    }

    if (!isLastStep && activeSubNav.id !== 1) {
      if (changeRequested) {
        await nextStep();

        if (parseInt(changeRequested, 10) === 1 && activeStep === 1) {
          const response = await saveAssessment(data, activeSubNav.id);
          if (isHelpAvailable && response.data) {
            await handleAssessmentData({
              assessmentId: response?.data?.assessment_id,
              questionnaireId: response?.data?.questionnaire_id,
            });
          }

          localStorage.removeItem('changeRequested');
          goToStep(isHelpAvailable ? 25 : 24);
        }

        if (parseInt(changeRequested, 10) === activeStep) {
          const response = await saveAssessment(data, activeSubNav.id);
          if (isHelpAvailable && response.data) {
            await handleAssessmentData({
              assessmentId: response?.data?.assessment_id,
              questionnaireId: response?.data?.questionnaire_id,
            });
          }

          localStorage.removeItem('changeRequested');
          goToStep(isHelpAvailable ? 25 : 24);
        }
      } else {
        if (activeStep === 24) {
          const response = await saveAssessment(data, activeSubNav.id);
          if (isHelpAvailable && response.data) {
            await handleAssessmentData({
              assessmentId: response?.data?.assessment_id,
              questionnaireId: response?.data?.questionnaire_id,
            });
          }
        }

        if (idsQuestionaires.length > 0) {
          if (isHelpAvailable && activeStep === 27) {
            await saveFocusArea(idsQuestionaires, activeSubNav.id);
          } else if (!isHelpAvailable && activeStep === 26) {
            await saveFocusArea(idsQuestionaires, activeSubNav.id);
          }
        }

        await nextStep();
        dispatch({ type: SET_WIZZARD_STEP, payload: activeStep });
      }

      smoothScrollTo(0, 100);
    }
  };

  const handleFocusNavigation = async (type: string) => {
    if (type !== 'next') return;

    if (activeSubNav.id === 1)
      if (!((isHelpAvailable && activeStep === 24) || (!isHelpAvailable && activeStep === 23)))
        return;

    if (activeSubNav.id !== 1)
      if (!((isHelpAvailable && activeStep === 27) || (!isHelpAvailable && activeStep === 26)))
        return;

    if (
      reduxStarScores.currentStep !== 'NO_ACTIVE_PLANS' &&
      reduxStarScores.selectedFocusAreas.length !== 0
    ) {
      const res = await createImprovePlan(
        ImprovePlanTypes.DEEP_DIVE,
        null,
        activeSubNav.id,
        reduxStarScores?.selectedFocusAreas?.map((item) => item.id) || [],
      );

      if (res.id) {
        await updateActionsByImprovePlanId(res.id, ImprovePlanTypes.DEEP_DIVE, true);
      }

      await reduxDispatch(starScoresSliceActions.setCreatedImprovePlanDetails(res));
    }

    if (
      reduxStarScores.currentStep === 'NO_ACTIVE_PLANS' &&
      reduxStarScores.selectedFocusAreas.length > 0
    ) {
      const res = await createImprovePlan(
        ImprovePlanTypes.DEEP_DIVE,
        null,
        activeSubNav.id,
        reduxStarScores?.selectedFocusAreas?.map((item) => item.id) || [],
      );

      if (res.id) {
        await updateActionsByImprovePlanId(res.id, ImprovePlanTypes.DEEP_DIVE, true);
      }

      await reduxDispatch(starScoresSliceActions.setCreatedImprovePlanDetails(res));
    }

    if (
      reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans &&
      reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans[0]?.id &&
      reduxStarScores.selectedFocusAreas.length === 0
    ) {
      const res = await createImprovePlan(
        ImprovePlanTypes.DEEP_DIVE,
        null,
        activeSubNav.id,
        reduxStarScores?.selectedFocusAreas?.map((item) => item.id) || [],
      );

      if (res.id) {
        await updateActionsByImprovePlanId(res.id, ImprovePlanTypes.DEEP_DIVE, true);
      }
    }

    switch (reduxStarScores.currentStep) {
      case 'NO_ACTIVE_PLANS':
        await reduxDispatch(
          starScoresSliceActions.setSubNavigationStep(
            reduxStarScores.selectedFocusAreas.length === 0
              ? 'noPlanAndFocusAreas'
              : 'planCreatedWithFocusAreas',
          ),
        );
        break;
      case 'HAS_PLANS_NO_FOCUS_AREAS':
        await reduxDispatch(
          starScoresSliceActions.setSubNavigationStep(
            reduxStarScores.selectedFocusAreas.length === 0
              ? 'hasPlanNoFocusAreas'
              : 'hasPlanAndUpdatedFocusAreas',
          ),
        );
        break;
      case 'HAS_BOTH_PLANS_AND_FOCUS_AREAS':
        await reduxDispatch(
          starScoresSliceActions.setSubNavigationStep(
            reduxStarScores.selectedFocusAreas.length === 0
              ? 'hasPlanAndRemovedFocusAreas'
              : 'hasPlanAndUpdatedFocusAreas',
          ),
        );
        break;
      default:
        break;
    }

    await nextStep();
    await dispatch({ type: SET_WIZZARD_STEP, payload: activeStep });
  };

  const handlePrevious = () => {
    collapseSidebar(true);
    if (!isFirstStep) {
      previousStep();
      localStorage.removeItem('changeRequested');
    }
  };

  const handleCancel = () => {
    collapseSidebar(true);
    history.push('/');
    smoothScrollTo(0, 100);
  };

  const handleComplete = async () => {
    collapseSidebar(true);
    reduxDispatch(updateHelpAvailability(false));
    reduxDispatch(starScoresSliceActions.resetStarScores());
    smoothScrollTo(0, 100);

    if (reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas') {
      history.push(`/stars`);
    } else if (reduxStarScores.createdImprovementPlan) {
      history.push(
        `/improve-plans/?improveAreaId=${reduxStarScores.createdImprovementPlan.id}&improvePlan=${undefined}`,
      );
    } else if (
      reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans &&
      reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans?.length > 0
    ) {
      history.push(
        `/improve-plans/?improveAreaId=${reduxStarScores.deepDiveQuestionWithPlans[0].question.improvePlans[0].id}&improvePlan=${reduxStarScores.deepDiveQuestionWithPlans[0].question?.questionText}`,
      );
    }
  };

  const isButtonsDisabled = () => {
    let isDisabled = true;
    if (isHelpAvailable) {
      if (activeStep > 0 && activeStep < 25) {
        if (activeStep !== 1) {
          const category = data.flatMap((k) => k.data);

          if (category[activeStep - 1] && activeSubNav.id !== 1) {
            isDisabled = false;
          }

          if (category[activeStep + 2] && activeSubNav.id === 1) {
            isDisabled = false;
          }
        } else if (data[0].data?.[0] !== 0) {
          isDisabled = false;
        }
      }

      if ((activeSubNav.id === 1 && activeStep > 22) || activeStep > 25) {
        isDisabled = false;
        // if (
        //   (activeSubNav.id === 1 && activeStep === 24 && selectedCount < 1) ||
        //   (activeSubNav.id !== 1 && activeStep === 27 && selectedCount < 1)
        // ) {
        //   isDisabled = true;
        // }
      }
    } else if (!isHelpAvailable) {
      if (activeStep > 0 && activeStep < 25) {
        if (activeStep !== 1) {
          const category = data.flatMap((k) => k.data);

          if (category[activeStep - 1] && activeSubNav.id !== 1) {
            isDisabled = false;
          }

          if (category[activeStep + 2] && activeSubNav.id === 1) {
            isDisabled = false;
          }
        } else if (data[0].data?.[0] !== 0) {
          isDisabled = false;
        }
      }

      if ((activeSubNav.id === 1 && activeStep > 22) || activeStep > 24) {
        isDisabled = false;
        // if (
        //   (activeSubNav.id === 1 && activeStep === 23 && selectedCount < 1) ||
        //   (activeSubNav.id !== 1 && activeStep === 26 && selectedCount < 1)
        // ) {
        //   isDisabled = true;
        // }
      }
    }

    return isDisabled;
  };

  const handleNextOnSkip = async () => {
    if (isHelpAvailable && activeStep === 23) {
      await handleAssessmentData({ assessmentId: 0, questionnaireId: 0 });
      await handleNext();
    } else {
      handleNext();
    }
  };

  const handleActionsInHelp = async () => {
    if (activeStep > 22 && !isLastStep) {
      if (isHelpAvailable && activeStep === 22) {
        await logStarInstantHelp();
        await handleNextOnSkip();
      } else {
        handleNextOnSkip();
      }
    }
  };

  const skipFocusArea = async () => {
    if (
      reduxStarScores.selectedFocusAreas.length > 0 &&
      reduxStarScores.currentStep !== 'NO_ACTIVE_PLANS'
    ) {
      if (
        reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans &&
        reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans[0]?.id
      ) {
        await updateActionsByImprovePlanId(
          reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans[0]?.id,
          ImprovePlanTypes.DEEP_DIVE,
          true,
        );
      }

      reduxDispatch(starScoresSliceActions.setSubNavigationStep('hasPlanAndUpdatedFocusAreas'));
      await nextStep();
    } else {
      if (
        reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans &&
        reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans[0]?.id
      ) {
        await updateActionsByImprovePlanId(
          reduxStarScores?.deepDiveQuestionWithPlans[0]?.question?.improvePlans[0]?.id,
          ImprovePlanTypes.DEEP_DIVE,
          true,
        );
      }

      reduxDispatch(starScoresSliceActions.setSubNavigationStep('noPlanAndFocusAreas'));
      await nextStep();
    }
  };

  if (!isFirstStep && activeSubNav.id === 1) {
    return (
      <div className={styles.hsStepWizardFooter}>
        <div
          className={`${styles.flexContainer} ${
            (!isHelpAvailable && activeStep === 22) || (isHelpAvailable && activeStep === 23)
              ? styles.flexColumn
              : ''
          }`}
        >
          {!isHelpAvailable && activeStep === 23 && (
            <div
              className={styles.hsStepWizardFooterSelectedFocusAreas}
              style={{ marginBottom: 20 }}
            >
              <p style={{ fontSize: 14 }}>Select up to 3 areas that you’d like to improve on</p>
              <p>Selected: {selectedCount}</p>
            </div>
          )}
          {isHelpAvailable && activeStep === 24 && (
            <div
              className={styles.hsStepWizardFooterSelectedFocusAreas}
              style={{ marginBottom: 20 }}
            >
              <p style={{ fontSize: 14 }}>Select up to 3 areas that you’d like to improve on</p>
              <p>Selected: {selectedCount}</p>
            </div>
          )}
          {!isHelpAvailable && activeStep < 22 && (
            <div
              onClick={handlePrevious}
              className={`${styles.commonBackContainerStyle} ${
                isFirstStep ? styles.backIconContainerGray : styles.backIconContainerBlue
              }`}
            >
              <Img src={backIcon} alt="back icon" />
            </div>
          )}
          {isHelpAvailable && activeStep < 22 && (
            <div
              onClick={handlePrevious}
              className={`${styles.commonBackContainerStyle} ${
                isFirstStep ? styles.backIconContainerGray : styles.backIconContainerBlue
              }`}
            >
              <Img src={backIcon} alt="back icon" />
            </div>
          )}
          <div className={styles.hsStepWizardFooterInner}>
            {!isHelpAvailable && activeStep < 22 && (
              <DefaultButton variant="secondary" type="button" onClick={handleCancel}>
                Cancel
              </DefaultButton>
            )}
            {isHelpAvailable && activeStep < 22 && (
              <DefaultButton variant="secondary" type="button" onClick={handleCancel}>
                Cancel
              </DefaultButton>
            )}
            {!isHelpAvailable && !isLastStep && activeStep < 22 && (
              <DefaultButton
                variant="primary"
                type="button"
                onClick={handleNext}
                disabled={isButtonsDisabled()}
              >
                {activeSubNav.id !== 1
                  ? parseInt(changeRequested, 10) === activeStep
                    ? 'Finish'
                    : 'Next'
                  : parseInt(changeRequested, 10) + 3 === activeStep
                  ? 'Finish'
                  : 'Next'}
              </DefaultButton>
            )}
            {isHelpAvailable &&
              !isLastStep &&
              activeStep < 22 &&
              ((!isHelpAvailable && activeStep !== 22) ||
                (isHelpAvailable && activeStep !== 23)) && (
                <DefaultButton
                  variant="primary"
                  type="button"
                  onClick={handleNext}
                  disabled={isButtonsDisabled()}
                >
                  {activeSubNav.id !== 1
                    ? parseInt(changeRequested, 10) === activeStep
                      ? 'Finish'
                      : 'Next'
                    : parseInt(changeRequested, 10) + 3 === activeStep
                    ? 'Finish'
                    : 'Next'}
                </DefaultButton>
              )}

            {!isHelpAvailable && activeStep === 22 && (
              <DefaultButton variant="primary" type="button" onClick={handleNext}>
                Continue
              </DefaultButton>
            )}
            {isHelpAvailable && activeStep === 22 && (
              <DefaultButton variant="primary" type="button" onClick={handleNext}>
                Continue
              </DefaultButton>
            )}
            {activeStep > 22 && !isLastStep && (
              <>
                {((isHelpAvailable && activeStep === 24) ||
                  (!isHelpAvailable && activeStep === 23)) && (
                  <DefaultButton variant="secondary" type="button" onClick={skipFocusArea}>
                    Skip
                  </DefaultButton>
                )}

                <DefaultButton
                  variant="primary"
                  type="button"
                  onClick={() => {
                    if (
                      (isHelpAvailable && activeStep === 24) ||
                      (!isHelpAvailable && activeStep === 23)
                    ) {
                      handleFocusNavigation('next');
                    } else {
                      handleNextOnSkip();
                    }
                  }}
                >
                  {isHelpAvailable && activeStep === 23 ? customButtonName : 'Continue'}
                </DefaultButton>
              </>
            )}
            {isLastStep && (
              <DefaultButton variant="primary" type="button" onClick={handleComplete}>
                {reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas'
                  ? 'Finish'
                  : 'Take me to my plan'}
              </DefaultButton>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (!isFirstStep && activeSubNav.id !== 1) {
    return (
      <div className={styles.hsStepWizardFooter}>
        <div
          className={`${styles.flexContainer} ${
            (!isHelpAvailable && activeStep === 25) || (isHelpAvailable && activeStep === 26)
              ? styles.flexColumn
              : ''
          }`}
        >
          {!isHelpAvailable && activeStep === 26 && (
            <div
              className={styles.hsStepWizardFooterSelectedFocusAreas}
              style={{ marginBottom: 20 }}
            >
              <p style={{ fontSize: 14 }}>Select up to 3 areas that you’d like to improve on</p>
              <p>Selected: {selectedCount}</p>
            </div>
          )}
          {isHelpAvailable && activeStep === 27 && (
            <div
              className={styles.hsStepWizardFooterSelectedFocusAreas}
              style={{ marginBottom: 20 }}
            >
              <p style={{ fontSize: 14 }}>Select up to 3 areas that you’d like to improve on</p>
              <p>Selected: {selectedCount}</p>
            </div>
          )}
          {!isHelpAvailable && activeStep < 25 && (
            <div
              onClick={handlePrevious}
              className={`${styles.commonBackContainerStyle} ${
                isFirstStep ? styles.backIconContainerGray : styles.backIconContainerBlue
              }`}
            >
              <Img src={backIcon} alt="back icon" />
            </div>
          )}
          {isHelpAvailable && activeStep < 25 && (
            <div
              onClick={handlePrevious}
              className={`${styles.commonBackContainerStyle} ${
                isFirstStep ? styles.backIconContainerGray : styles.backIconContainerBlue
              }`}
            >
              <Img src={backIcon} alt="back icon" />
            </div>
          )}
          <div className={styles.hsStepWizardFooterInner}>
            {!isHelpAvailable && activeStep < 25 && (
              <DefaultButton variant="secondary" type="button" onClick={handleCancel}>
                Cancel
              </DefaultButton>
            )}
            {isHelpAvailable && activeStep < 25 && (
              <DefaultButton variant="secondary" type="button" onClick={handleCancel}>
                Cancel
              </DefaultButton>
            )}
            {!isHelpAvailable && !isLastStep && activeStep < 25 && (
              <DefaultButton
                variant="primary"
                type="button"
                onClick={handleNext}
                disabled={isButtonsDisabled()}
              >
                {activeSubNav.id !== 1
                  ? parseInt(changeRequested, 10) === activeStep
                    ? 'Finish'
                    : 'Next'
                  : parseInt(changeRequested, 10) + 3 === activeStep
                  ? 'Finish'
                  : 'Next'}
              </DefaultButton>
            )}
            {isHelpAvailable &&
              !isLastStep &&
              activeStep < 25 &&
              ((!isHelpAvailable && activeStep !== 25) ||
                (isHelpAvailable && activeStep !== 26)) && (
                <DefaultButton
                  variant="primary"
                  type="button"
                  onClick={handleNext}
                  disabled={isButtonsDisabled()}
                >
                  {activeSubNav.id !== 1
                    ? parseInt(changeRequested, 10) === activeStep
                      ? 'Finish'
                      : 'Next'
                    : parseInt(changeRequested, 10) + 3 === activeStep
                    ? 'Finish'
                    : 'Next'}
                </DefaultButton>
              )}

            {!isHelpAvailable && activeStep === 25 && (
              <DefaultButton variant="primary" type="button" onClick={handleNext}>
                Continue
              </DefaultButton>
            )}
            {isHelpAvailable && activeStep === 25 && (
              <DefaultButton variant="primary" type="button" onClick={handleNext}>
                Continue
              </DefaultButton>
            )}
            {activeStep > 25 && !isLastStep && (
              <>
                {((isHelpAvailable && activeStep === 27) ||
                  (!isHelpAvailable && activeStep === 26)) && (
                  <DefaultButton variant="secondary" type="button" onClick={skipFocusArea}>
                    Skip
                  </DefaultButton>
                )}

                <DefaultButton
                  variant="primary"
                  type="button"
                  onClick={() => {
                    if (
                      (isHelpAvailable && activeStep === 27) ||
                      (!isHelpAvailable && activeStep === 26)
                    ) {
                      handleFocusNavigation('next');
                    } else {
                      handleNextOnSkip();
                    }
                  }}
                >
                  {isHelpAvailable && activeStep === 26 ? customButtonName : 'Continue'}
                </DefaultButton>
              </>
            )}
            {isLastStep && (
              <DefaultButton variant="primary" type="button" onClick={handleComplete}>
                {reduxStarScores.subNavigationStep === 'noPlanAndFocusAreas'
                  ? 'Finish'
                  : 'Take me to my plan'}
              </DefaultButton>
            )}
          </div>
        </div>
      </div>
    );
  }

  return <div className={styles.hsStepWizardStartFooter} />;
};

interface FailsQuestionsInterface {
  question_id: number;
  limit: number;
}

interface StepWizardFooterMobileInterface {
  handleAssessmentData: (data: { assessmentId: number; questionnaireId: number }) => void;
  assesmentData: { assessmentId: number; questionnaireId: number };
  skipToAnyStep: number;
  handleStarSkip: (arg0: number) => void;
  customButtonName: string;
}

export default StepWizardFooterMobile;
