import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import logo from '@assets/images/logo.svg';
import HeaderWizard from '@components/HeaderWizard';
import { CreateStarContext } from '@context/CreateStarContext';
import { SET_CURRENT_TAB, SET_FOCUS_AREAS, useGlobalState } from '@context/GlobalStore';
import classNames from 'classnames';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import Img from '@components/Atoms/Img';
import { getCompanyDetails } from '@helpers/auth.helper';
import { CompanyDetails } from 'custom';
import HeaderWizardMobile from '../HeaderMobile';
import styles from './index.module.scss';

const Header: React.FunctionComponent = () => {
  const { isMobile } = useWindowDimensions();
  const history = useHistory();
  const [state, dispatch] = useGlobalState();
  const { categoryQuestion, setCategoryQuestion } = useContext(CreateStarContext);
  const { toggleSubNav } = useContext(SecondaryNavigationContext);
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>();
  let organizationLogo;
  let organizationAltText = 'organization logo';

  useEffect(() => {
    const getCompanyData = async () => {
      const data = await getCompanyDetails();
      if (data) {
        setCompanyDetails(data);
      }
    };

    getCompanyData();
  }, []);

  if (companyDetails) {
    organizationLogo = companyDetails.company_logo
      ? `${process.env.PUBLIC_URL}/images/logos/${companyDetails.company_logo}`
      : null;
    organizationAltText = companyDetails.company_name && `${companyDetails.company_name} logo`;
  }

  return (
    <div
      className={classNames(['sticky-top', styles.hsHeader])}
      style={{ padding: isMobile ? '10px 30px ' : undefined }}
    >
      <div className={styles.hsNav}>
        <div className={styles.cont}>
          <div
            className={classNames([styles.navItemContainer, styles.hsImgContainer])}
            onClick={() => {
              setCategoryQuestion(
                categoryQuestion.map((i: any, key) => {
                  const newStructure = categoryQuestion[key];
                  newStructure.data = [0, 0, 0, 0];
                  newStructure.questions = i.questions.map((o: any) => ({
                    ...o,
                    score: 0,
                    isSelected: false,
                  }));

                  return newStructure;
                }),
              );

              dispatch({ type: SET_FOCUS_AREAS, payload: [] });

              history.push('/');
            }}
          >
            {!isMobile && (
              <button
                type="button"
                onClick={() => {
                  dispatch({ type: SET_CURRENT_TAB, payload: { id: 1, name: 'Overall' } });
                  toggleSubNav({ id: 1, name: 'Overall' });
                }}
                style={{ border: 'none', backgroundColor: 'white' }}
              >
                {' '}
                <Img alt="Hapstar logo" src={logo} className={classNames([styles.img])} />
              </button>
            )}
          </div>
          {isMobile && <HeaderWizardMobile />}
          {!isMobile && <HeaderWizard />}
          <div className={styles.orgImgContainer}>
            {!isMobile && organizationLogo && (
              <Img alt={organizationAltText} src={organizationLogo} className={styles.img} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
