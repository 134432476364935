import React from 'react';
import { Slider as DefaultSlider } from '@mui/material';
import { styled } from '@mui/material/styles';

import styles from './index.module.scss';

const CustomizedSlider = styled(DefaultSlider)({
  color: '#019DF2',
  height: 4,
  '& .DefaultSlider-track': {
    border: 'none',
  },
  '& .DefaultSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#019DF2',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.DefaultSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const CustomSlider: React.FC<SliderProps> = (props) => {
  const {
    sliderValue,
    handleSliderChange,
    defaultValue,
    step,
    marks,
    minValue,
    maxValue,
    valueLabel,
    className,
  } = props;

  return (
    <div className={className}>
      <CustomizedSlider
        aria-label="Small steps"
        defaultValue={defaultValue}
        step={step}
        marks={marks}
        min={minValue}
        max={maxValue}
        value={sliderValue}
        onChange={handleSliderChange}
        valueLabelDisplay={valueLabel}
        valueLabelFormat={(value: number) => `${value}`}
      />
    </div>
  );
};

CustomSlider.defaultProps = {
  className: styles.root,
  minValue: 0,
  maxValue: 10,
  sliderValue: 7,
  step: 1,
  defaultValue: 7,
  valueLabel: 'on',
};

interface SliderProps {
  sliderValue?: number;
  handleSliderChange: any;
  defaultValue?: number;
  step?: number;
  marks: boolean;
  minValue?: number;
  maxValue?: number;
  valueLabel?: 'on' | 'off' | 'auto';
  className?: string;
}

export default CustomSlider;
