import React, { useContext, useEffect } from 'react';
import OnboardingLayout from '@layouts/OnboardingLayout';
import QuestionItemContainer from '@components/QuestionItemContainer';
import QuestionItemContainerMobile from '@components/QuestionItemContainerMobile';
import { ProSidebarProvider } from 'react-pro-sidebar';
import {
  SET_FOCUS_AREAS,
  SET_GRAPH_DATA,
  SET_IS_USER_ONBOARDING,
  useGlobalState,
} from '@context/GlobalStore';
import CreateStarContextProvider, { CreateStarContext } from '@context/CreateStarContext';
import ScoreCheckbox from '@components/ScoreCheckbox';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import { useHistory } from 'react-router';
import { isUserOnBoarded } from '@api/users';
import { checkLocalStorage, TOKEN } from '@api/config';

const CreateStar: React.FunctionComponent = () => {
  const { categoryQuestion, setCategoryQuestion } = useContext(CreateStarContext);
  const [state, dispatch] = useGlobalState();
  const { isMobile } = useWindowDimensions();
  const { activeSubNav, toggleSubNav } = useContext(SecondaryNavigationContext);
  const history = useHistory();

  const token = localStorage.getItem('token');

  useEffect(() => {
    setCategoryQuestion(
      categoryQuestion.map((i: any, key) => {
        const newStructure = categoryQuestion[key];
        newStructure.data = [0, 0, 0, 0];
        newStructure.questions = i.questions.map((o: any) => ({
          ...o,
          score: 0,
          isSelected: false,
        }));

        return newStructure;
      }),
    );

    dispatch({ type: SET_FOCUS_AREAS, payload: [] });

    dispatch({ type: SET_GRAPH_DATA, payload: { assesmentId: 0, date: '', index: 0 } });
  }, []);

  useEffect(() => {
    if (state.currentTab?.id) {
      toggleSubNav(state.currentTab);
    }
  }, [state.currentTab]);

  useEffect(() => {
    const getIsUserOnboarded = async () => {
      const data = await isUserOnBoarded();
      dispatch({ type: SET_IS_USER_ONBOARDING, payload: data });
    };

    if (token) {
      getIsUserOnboarded();
    }
  }, [dispatch, token]);

  return (
    <ProSidebarProvider>
      <OnboardingLayout title="Create My Star" showFooter={false}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">{isMobile && <QuestionItemContainerMobile />}</div>
            <div className="col-12">{!isMobile && <QuestionItemContainer />}</div>
          </div>
        </div>
      </OnboardingLayout>
    </ProSidebarProvider>
  );
};

export default CreateStar;
