import React from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import Modal from '@components/Atoms/Modal';
import DefaultButton from '@components/Atoms/DefaultButton';
import FormTextarea from '@components/FormTextarea';
import styles from './index.module.scss';

const defaultValues = {
  content: '',
};

const CommentResponseModal: React.FunctionComponent<CommentResponseModalProps> = (props) => {
  const { title, isOpen, onClose, onSave } = props;

  const validationSchema = yup.object({
    content: yup.string().required().min(1).max(3000).required(),
  });

  const methods = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
  } = methods;

  const onSubmit = (data: { content: string }) => {
    onSave(data, methods);
  };

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  return (
    <div>
      <Modal
        open={isOpen}
        setOpen={handleClose}
        overlay
        onTapBackgroundClose
        className={styles.modalRoot}
      >
        <DefaultButton
          color="primary"
          type="button"
          className={styles.btnClose}
          onClick={handleClose}
        >
          <i className={classNames('icon', 'icon-x', styles.btnCloseIcon)} />
        </DefaultButton>
        <div className={styles.modalBody}>
          <div className={styles.modalTitle}>{title}</div>
          <hr />

          <div>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.formArea}>
              <FormTextarea {...register('content')} rows={10} isError={Boolean(errors.content)} />
              <div className={styles.btnArea}>
                <DefaultButton
                  type="submit"
                  variant="primary"
                  disabled={!methods.formState.isValid}
                >
                  Send
                </DefaultButton>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

interface CommentResponseModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void | undefined;
  onSave: (data: { content: string }, methods: any) => void;
}

export default CommentResponseModal;
