import { addBooking } from '@api/experts';
import CalendarIcon from '@assets/images/icons/calendar-check-icon.svg';
import ClockIcon from '@assets/images/icons/clock-icon.svg';
import RotateIcon from '@assets/images/icons/rotate-icon.svg';
import UserIcon from '@assets/images/icons/user-icon.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import { ExpertBooking, ExpertInterface } from '@interfaces/index';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styles from './index.module.scss';

const ConfirmBooking: React.FunctionComponent<ConfirmBookingProps> = ({
  handleBookingEnabling,
  bookingData,
  timezoneDetails,
  expertDetails,
}) => {
  const [isBookingSuccess, setIsBookingSuccess] = useState(false);

  const formatDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);

    const options: any = { weekday: 'short', day: 'numeric', month: 'short' };
    const formattedDate = date.toLocaleDateString('en-GB', options);

    return formattedDate;
  };

  function convertToUTC(dateObj: { date: string | null; time: string | null }) {
    const { date, time } = dateObj;
    const localDateTime = new Date(`${date}T${time}:00`);
    const utcDateTime = new Date(localDateTime.toISOString());

    return utcDateTime.toISOString().replace('.000Z', '');
  }

  const confirmBooking = async () => {
    try {
      const req: ExpertBooking | { errors: any[] } = await addBooking(
        convertToUTC(bookingData),
        expertDetails.id,
      );
      if ('errors' in req && Array.isArray(req.errors) && req.errors.length > 0) {
        toast.error(req.errors[0].message || 'Booking failed. Please try again.');
      } else {
        setIsBookingSuccess(true);
        toast.success('Booking successful. Please check your email for more information.');
      }
    } catch (e) {
      setIsBookingSuccess(false);
      toast.error('Error occurred. Try again later or contact admin.');
    }
  };

  return (
    <>
      <div className={styles.bookingContainer}>
        <div className={styles.card}>
          <h5>Confirm your booking</h5>

          {isBookingSuccess && (
            <div className={styles.checkBoxWrapper}>
              {' '}
              <svg
                className={styles.checkmark}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                {' '}
                <circle
                  className={styles.checkmarkCircle}
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />{' '}
                <path
                  className={styles.checkmarkCheck}
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>
          )}

          <div className={styles.bookingInfoSection}>
            <div className={styles.bookingInfo}>
              <Img src={UserIcon} alt="user-icon" className={styles.icon} />
              <p>{`${expertDetails.firstName} ${expertDetails.lastName}`}</p>
              <p>&nbsp;</p>
            </div>
            <div className={styles.bookingInfo}>
              <Img src={CalendarIcon} alt="calendar-icon" className={styles.icon} />
              <p>{bookingData.date && formatDate(bookingData.date)}</p>
              <p>&nbsp;</p>
            </div>
            <div className={styles.bookingInfo}>
              <Img src={ClockIcon} alt="clock-icon" className={styles.icon} />
              <p>{`${bookingData.time} (${timezoneDetails.timeZone})`}</p>
              <p>&nbsp;</p>
            </div>
            <div className={styles.bookingInfo}>
              <Img src={RotateIcon} alt="rotate-icon" className={styles.icon} />
              <p>25 Minutes</p>
              <p>&nbsp;</p>
            </div>
          </div>

          {isBookingSuccess && (
            <p className={styles.successMessage}>
              You will receive an email with the link to your video session. If you don’t receive
              the email, please ensure to check your spam/junk folder.
            </p>
          )}

          <div className={styles.btnContainer}>
            {isBookingSuccess ? (
              <div className={styles.btns}>
                <DefaultButton
                  variant="secondary"
                  onClick={() => handleBookingEnabling({ enabled: false, date: null, time: null })}
                >
                  Go back
                </DefaultButton>{' '}
              </div>
            ) : (
              <div className={styles.btns}>
                <DefaultButton onClick={confirmBooking}>Complete Booking</DefaultButton>

                <DefaultButton
                  variant="secondary"
                  onClick={() => handleBookingEnabling({ enabled: false, date: null, time: null })}
                >
                  Cancel
                </DefaultButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

interface ConfirmBookingProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  handleBookingEnabling: (arg0: {
    enabled: boolean;
    date: string | null;
    time: string | null;
  }) => void;
  bookingData: {
    enabled: boolean;
    date: string | null;
    time: string | null;
  };
  timezoneDetails: {
    id: number;
    name: string;
    timeZone: string;
  };
  expertDetails: ExpertInterface;
}

export default ConfirmBooking;
