import React, { useEffect, useState } from 'react';
import QuestionItemContentMobile from '@components/QuestionItemContentMobile';
import Radar from '@components/Charts/Radar';
import PopUpModal from '@components/PopUpModal';
import { useGlobalState } from '@context/GlobalStore';
import useWindowDimensions from '@hooks/useWindowDimensions';
import RadarMobile from '@components/Charts/RadarMobile/Radar';
import Button from '@components/Button';
import { useProSidebar } from 'react-pro-sidebar';
import { getCompanyDetails } from '@helpers/auth.helper';
import styles from './index.module.scss';

const QuestionItemMobile: React.FunctionComponent<QuestionItemInterface> = (props) => {
  const { categoryQuestions, question, onChange, onSidebarOpen, showPopUp, collapsed } = props;
  const { isMobile } = useWindowDimensions();
  const [open, setOpen] = useState(true);
  const [openSecond, setOpenSecond] = useState(true);
  const [state] = useGlobalState();
  const { collapseSidebar } = useProSidebar();
  const [currentCompanyName, setCurrentCompanyName] = useState('');
  const [shareThoughts, setShareThoughts] = useState(true);

  useEffect(() => {
    const getCurrentCompanyDetails = async () => {
      const comDetails = await getCompanyDetails();
      if (comDetails) {
        setCurrentCompanyName(comDetails.company_name);
        setShareThoughts(comDetails.share_thoughts);
      }
    };

    getCurrentCompanyDetails();
  }, []);

  const handleClick = () => {
    collapseSidebar(false);
    onSidebarOpen(question.score, question.color, question.name, question.id);
  };

  const isButtonsDisabled = () => {
    let isDisabled = true;
    if (question.score > 0) {
      isDisabled = false;
    }

    return isDisabled;
  };

  return (
    <div className={styles.hsQuestionRhombus}>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        {shareThoughts !== undefined && !shareThoughts ? (
          <></>
        ) : (
          <Button type="small" isButton onClick={handleClick} isDisabled={isButtonsDisabled()}>
            Share thoughts
          </Button>
        )}
      </div>
      {showPopUp && collapsed && state?.historicalData.length < 1 && (
        <PopUpModal
          open={open}
          setOpen={setOpen}
          top="200px"
          right="50px"
          firstDescription='each driver is asking "how happy am I with…" with 10 being "Very happy" and 1 being "Very unhappy".'
          description="If you're unsure what the driver is asking, press the '?' for more information."
          title="Remember…"
        />
      )}
      {showPopUp && collapsed && !open && state?.isUserOnBoarded && (
        <PopUpModal
          top="200px"
          right="50px"
          open={openSecond}
          setOpen={setOpenSecond}
          firstDescription="Journaling is a powerful tool to expand on why you score a certain way."
          description={`You can keep your thoughts private as a personal journal, or share them anonymously with ${currentCompanyName}.`}
          title="Sharing your thoughts"
        />
      )}
      {isMobile && (
        <>
          <div
            className={styles.hsQuestionRhombusRadar}
            style={{
              position: 'relative',
            }}
          >
            <div>
              <RadarMobile
                color={categoryQuestions.color}
                lightColor={categoryQuestions.lightColor}
                darkColor={categoryQuestions.darkColor}
                data={[question.score, question.score, question.score, question.score]}
              />
            </div>
            <div style={{ position: 'absolute', left: 170, top: 15 }}>
              <QuestionItemContentMobile
                question={question}
                onChange={onChange}
                onSidebarOpen={onSidebarOpen}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

QuestionItemMobile.defaultProps = {
  showPopUp: false,
  collapsed: false,
};

interface QuestionItemInterface {
  categoryQuestions: any;
  question: any;
  showPopUp?: boolean;
  collapsed?: boolean;
  onChange: (score: number, questionId: number) => void;
  onSidebarOpen: (score: number, color: string, question: string, id: number) => void;
}

export default QuestionItemMobile;
