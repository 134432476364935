import { getCompanyDetailsById } from '@api/auth/company';
import { LoginTypes, verifyInviteTokenRequest } from '@api/auth/login';
import { logoutAuthenticatedUser } from '@api/auth/logout';
import { AuthResponse, CompanyDetails, updateUserStatusRequest, userStatusResponse } from 'custom';
import { toast } from 'react-toastify';

export const getCompanyDetails = async () => {
  const company = localStorage.getItem('company');
  const companyDetails: CompanyDetails | null = company && JSON.parse(company);

  if (companyDetails) {
    return companyDetails;
  }

  const user = localStorage.getItem('user');
  const companyId = user && JSON.parse(user).company_id;
  let data: CompanyDetails | null = null;
  if (companyId) {
    data = await getCompanyDetailsById(companyId);
    if (data) {
      localStorage.setItem('company', JSON.stringify(data));
    }
  }

  return data;
};

export const getTokenVerifiedUser = async (
  loginToken: string,
  type: LoginTypes,
  isNative: boolean,
  email: string | undefined,
  id: string | undefined,
  oneSignalDetails: any,
) => {
  const body = {
    loginToken,
    type,
    isNative,
    email,
    id,
    oneSignalId: oneSignalDetails
      ? oneSignalDetails.oneSignalUserId
        ? oneSignalDetails.oneSignalUserId
        : oneSignalDetails.oneSignalId
      : null,
  };
  const data = (await verifyInviteTokenRequest(body)) as AuthResponse;

  localStorage.clear();
  if (data.token) {
    localStorage.setItem('token', data.token);
  }

  if (data.user) {
    localStorage.setItem('user', JSON.stringify(data.user));
    localStorage.setItem('v', '2.0');
  }

  return data;
};

export const logoutUser = async () => {
  const logout = await logoutAuthenticatedUser();

  if (logout?.isSuccessful) {
    window.location.href = '/login?action=logout';
    await localStorage.clear();
    await localStorage.removeItem('redirectPath');
  }
};
