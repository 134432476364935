import React, { useEffect, useState } from 'react';
import { getCompanyDetails } from '@helpers/auth.helper';
import CompleteRegistration from '@components/Templates/CompleteRegistration';

const CompleteRegistrationPage: React.FunctionComponent = () => {
  const currentUser = localStorage.getItem('user') as any;
  const [companyDetails, setCompanyDetails] = useState<any>(null);
  let organizationLogo;
  let organizationAltText;
  let organizationId = 0;

  useEffect(() => {
    const getCompanyData = async () => {
      const data = await getCompanyDetails();
      if (data) {
        setCompanyDetails(data);
      }
    };

    getCompanyData();
  }, []);

  if (companyDetails !== null) {
    organizationLogo =
      companyDetails.company_logo &&
      `${process.env.PUBLIC_URL}/images/logos/${companyDetails.company_logo}`;
    organizationAltText = companyDetails.company_name && `${companyDetails.company_name} logo`;
    organizationId = companyDetails.id;
  }

  return (
    <CompleteRegistration
      organizationLogo={organizationLogo}
      organizationAltText={organizationAltText}
      organizationId={organizationId}
      user={JSON.parse(currentUser)}
    />
  );
};

export default CompleteRegistrationPage;
