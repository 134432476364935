import { LoginTypes, confirmUserLogin } from '@api/auth/login';
import Button from '@components/Button';
import OTPInput from '@components/Molecules/OTPInput';
import { getCompanyDetails, getTokenVerifiedUser } from '@helpers/auth.helper';
import { useAppDispatch } from '@hooks/useAppDispatch';
import useGoNative from '@hooks/useGoNative';
import tracker from 'OpenReplay';
//import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getUserStatusData } from 'store/Actions/UserStatusAction';
import styles from './index.module.scss';

const TokenInput: React.FunctionComponent<TokenInputProps> = (props) => {
  const { email } = props;
  const history = useHistory();
  const otpLength = 6;
  const [otp, setOtp] = useState('');
  const [isError, setIsError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const isMobileApp = useGoNative();
  const [oneSignalDetails, setOneSignalDetails] = useState(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      tracker.start();
    }
  });

  useEffect(() => {
    if (otp.trim().length === otpLength) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [otp, otp.length]);

  const handleOnChange = (value: string) => {
    setOtp(value);
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserStatusData());
      history.push('/');
    }
  }, [isAuthenticated, dispatch, history]);

  // eslint-disable-next-line camelcase
  function median_onesignal_info(oneSignalInfo: any) {
    setOneSignalDetails(oneSignalInfo);
  }

  const handleConfirm = async () => {
    try {
      const res = await getTokenVerifiedUser(
        otp,
        LoginTypes.TOKEN,
        isMobileApp,
        email,
        undefined,
        oneSignalDetails,
      );

      await getCompanyDetails();

      // mixpanel.identify(res.user.id);
      // mixpanel.track('Log In ( OTP Token)');

      setIsError(false);

      tracker.setUserID(res.user.id.toString());

      setIsAuthenticated(true);

      await confirmUserLogin({ type: LoginTypes.TOKEN });
    } catch (err: any) {
      setIsError(true);
      setIsAuthenticated(false);
    }
  };

  return (
    <>
      <div className={styles.tokenContainer}>
        <OTPInput value={otp} valueLength={otpLength} onChange={handleOnChange} isError={isError} />
      </div>
      <Button type="primary" isButton isDisabled={isDisabled} onClick={handleConfirm} width="100%">
        Continue
      </Button>
    </>
  );
};

TokenInput.displayName = 'TokenInput';

interface TokenInputProps {
  email: string;
}

export default TokenInput;
