import React, { useState } from 'react';
import EChartsReact from 'echarts-for-react';
import { WingDetails } from '@components/Star';
import useWindowDimensions from '@hooks/useWindowDimensions';
import dayjs from 'dayjs';
import styles from './index.module.scss';

const OldEmployerAreaChart: React.FunctionComponent<AreaProps> = (props) => {
  const { values, stars, selectedIndex, selectedTimeframeKey, months, selectedTabs } = props;
  const { isDesktop } = useWindowDimensions();
  const [selectedTabs1, setSelectedTabs1] = useState<any>([]);

  const modifiedValues =
    values?.[0]?.data.map((i: any) => ({
      score: i.value,
      assessmentId: i.assessment_id,
      date: dayjs(i.date).format('DD/MM/YYYY'),
    })) ?? [];

  const modifiedLabels = values?.[0]?.data.map((i: any) =>
    months > 1 ? dayjs(i.date).format('MMM') : dayjs(i.date).format('DD MMM'),
  );

  const modifiedLabels1 = modifiedLabels?.map((o: string, key: number) =>
    key === 0 || key === 7 || key === 14 || key === 21 || key === 28 ? o : '',
  );

  if (values.length === 1) {
    modifiedValues.unshift(modifiedValues[0]);
    modifiedLabels.unshift('');
  }

  const maxValue = isDesktop ? 10 : 5;
  let modifiedStars = [...stars];
  modifiedStars = new Array(maxValue - stars.length).fill([]);
  modifiedStars.push(...stars);
  modifiedStars.push([]);

  const labelData = selectedTimeframeKey === 0 ? modifiedLabels1 : modifiedLabels;

  const series = Object.values(values).map((n: any) =>
    n.data
      .map((i: any) => ({
        score: i.value,
        assessmentId: i.assessment_id,
        date: dayjs(i.date).format('DD/MM/YYYY'),
      }))
      .map((value: any, index: any) => {
        if (value.score === 0) {
          return {
            value: null,
            assessmentId: value.assessmentId,
            index,
            date: value.date,
            label: { show: false },
            symbol: 'none',
            symbolSize: 0,
          };
        }

        // if (index === selectedIndex) {
        //   return {
        //     value: value.score.toFixed(),
        //     decimalScore: value.score,
        //     assessmentId: value.assessmentId,
        //     index,
        //     date: value.date,
        //     symbolSize: 67,
        //     label: {
        //       fontSize: 20,
        //     },
        //   };
        // }

        return {
          value: value.score.toFixed(0),
          decimalScore: value.score.toFixed(0),
          assessmentId: value.assessmentId,
          index,
          date: value.date,
          label: { show: true },
          symbolSize: 40,
        };
      }),
  );

  const richData: RichDataObject = {
    value: {
      fontFamily: 'Titillium Web',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 30,
      align: 'center',
    },
    selected: {
      height: 30,
    },
  };

  const tabColors = ['#019DF2', '#BEAAF3', '#06BF97'];

  const series99 = tabColors.map((color, index) => ({
    data: series[index],
    type: 'line',
    symbolSize: 44,
    connectNulls: true,
    label: {
      show: true,
      position: 'inside',
      triggerEvent: true,
      fontSize: 16,
      fontFamily: 'Titillium Web',
      color: selectedTabs.includes(index) ? color : undefined,
    },
    itemStyle: {
      color,
      opacity: selectedTabs.includes(index) ? 1 : 0,
    },
    lineStyle: {
      color,
      opacity: selectedTabs.includes(index) ? 1 : 0,
    },
  }));

  const option = {
    grid: { right: 40, left: 40 },
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'axis' },
      formatter: (params: any) =>
        `Date: ${params[0].data.date}<br /> 
          <strong>${params[0]?.data?.decimalScore ? params[0]?.data?.decimalScore : 0}</strong>
          <br /> 
          <strong>${params[1]?.data?.decimalScore ? params[1]?.data?.decimalScore : 0}</strong>
          <br /> 
          <strong>${params[2]?.data?.decimalScore ? params[2]?.data?.decimalScore : 0}</strong>
        `,
    },

    xAxis: {
      type: 'category',
      boundaryGap: false,
      triggerEvent: true,
      data: labelData,
      axisPointer: {
        show: true,
        type: 'shadow',
        triggerEvent: false,
        trigger: 'item',
      },
      axisLabel: {
        triggerEvent: true,
        trigger: 'axis',
        interval: 0,
        formatter: (value: string) => [`{selected|}`, `${value}`].join('\n'),
        fontWeight: 'bold',
        rich: richData,
      },
    },
    yAxis: {
      type: 'value',
      interval: 10,
      min: -100,
      max: 100,
      triggerEvent: true,
      trigger: 'axis',
      axisLine: {
        show: true,
      },
      splitLine: {
        show: true,
      },
    },
    series: series99,
  };

  return (
    <div className={styles.hsAreaChart}>
      <EChartsReact
        option={option}
        style={{
          width: '100%',
          height: '550px',
        }}
      />
    </div>
  );
};

interface RichDataObject {
  [key: string]: string | number | object;
}

interface AreaProps {
  values: any[];
  stars: WingDetails[][];
  selectedIndex: number;
  months: any;
  selectedTimeframeKey: any;
  selectedTabs: any;
}

export default OldEmployerAreaChart;
